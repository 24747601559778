import React, { useEffect, useState } from "react";
import { Polygon, Tooltip, Marker, Popup, useMapEvent } from "react-leaflet";
import L from "leaflet";
import { getMapAudioArea } from "../../../../services/services";
import { convertCoordinates, getRandomColor } from "./mapUtils";

const ZoneComponent = ({ setActiveZone, setZoneOpacity, searchQuery }) => {
  const [zones, setZones] = useState([]);
  const [activeZoneLocal, setActiveZoneLocal] = useState(null);
  const [zoneOpacityLocal, setZoneOpacityLocal] = useState({});
  const [selectedPointIndex, setSelectedPointIndex] = useState(null);

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const { data, response } = await getMapAudioArea();
        if (response.status === 200) {
          setZones([...data.data]);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchZones();
  }, []);

  const handleZoneClick = (zone) => {
    if (selectedPointIndex === null) {
      setZoneOpacityLocal((prev) => ({
        ...prev,
        [zone.REGION_NAME]: prev[zone.REGION_NAME] === 0.8 ? 0.5 : 0.8,
      }));

      setZoneOpacity((prev) => ({
        ...prev,
        [zone.REGION_NAME]: prev[zone.REGION_NAME] === 0.8 ? 0.5 : 0.8,
      }));

      const points = zone.POINT_LIST.map((point) =>
        convertCoordinates(point.x, point.z),
      );

      setActiveZoneLocal({
        name: zone.REGION_NAME,
        points,
      });

      setActiveZone({
        name: zone.REGION_NAME,
        points,
      });
    }
  };

  const handleMarkerClick = (index) => {
    console.log(`Point ${index} selected for repositioning`);
    setSelectedPointIndex(index);
  };

  const MapClickHandler = () => {
    useMapEvent("click", (event) => {
      if (selectedPointIndex !== null && activeZoneLocal) {
        const { lat, lng } = event.latlng;
        const updatedPoints = [...activeZoneLocal.points];
        updatedPoints[selectedPointIndex] = [lat, lng];

        setActiveZoneLocal((prev) => ({
          ...prev,
          points: updatedPoints,
        }));

        setActiveZone((prev) => ({
          ...prev,
          points: updatedPoints,
        }));

        console.log(
          `Point ${selectedPointIndex} repositioned to [${lat}, ${lng}]`,
        );
        setSelectedPointIndex(null);
      }
    });
    return null;
  };

  return (
    <>
      <MapClickHandler />
      {zones
        .filter((item) => {
          if (searchQuery) {
            return item.REGION_NAME.toLowerCase().includes(
              searchQuery.toLowerCase(),
            );
          }
          return true;
        })
        .map((zone, index, filteredZones) => {
          if (!zone.ENABLE) return null;

          const latLngs = zone.POINT_LIST.map((point) =>
            convertCoordinates(point.x, point.z),
          );
          console.log("lat", latLngs);
          if (filteredZones.length === 1 && !activeZoneLocal) {
            handleZoneClick(zone);
          }

          return (
            <Polygon
              key={zone.REGION_NAME}
              positions={latLngs}
              color={getRandomColor(zones)}
              fillOpacity={zoneOpacityLocal[zone.REGION_NAME] || 0.8}
              eventHandlers={{
                click: () => handleZoneClick(zone),
              }}
            >
              <Tooltip sticky>{zone.REGION_NAME}</Tooltip>
            </Polygon>
          );
        })}

      {activeZoneLocal &&
        activeZoneLocal.points.map((point, index) => (
          <Marker
            key={index}
            position={point}
            icon={L.divIcon({
              className: "marker-circle",
              html: `<div style="
                background-color: ${
                  selectedPointIndex === index ? "red" : "white"
                };
                width: 10px; 
                height: 10px; 
                border-radius: 50%;
                cursor: pointer;"></div>`,
            })}
            eventHandlers={{
              click: () => handleMarkerClick(index),
            }}
          >
            <Tooltip>point :{index}</Tooltip>
          </Marker>
        ))}

      {activeZoneLocal && (
        <Polygon
          positions={activeZoneLocal.points}
          color="blue"
          fillOpacity={0.5}
        />
      )}
    </>
  );
};

export default ZoneComponent;
