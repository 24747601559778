import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import Notiflix from "notiflix";
import Buttons from "../../../../components/Button";
import {
  deleteImage,
  getGallery,
  postGallery,
  putImage,
} from "../../../../services/services";

// Validation schema
const ImageSchema = Yup.object().shape({
  name: Yup.string().required("Le nom est requis"),
  image: Yup.mixed(),
  description: Yup.string().required("La description est requise"),
  author: Yup.array().min(1, "Au moins un auteur est requis"),
  tags: Yup.array().min(1, "Au moins un tag est requis"),
  validated: Yup.boolean(),
});

// Predefined tags and authors
const initialTags = [
  "Build",
  "Terraforming",
  "Organique",
  "Novus Odyssey",
  "MineStrator",
];
const initialAuthors = ["Cancri", "Marodeur", "Thomas", "Lison", "NoCheat"];

const EditImageModal = ({ imageId, isOpen, onClose, onSubmit }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    image: null,
    description: "",
    author: [],
    tags: [],
    validated: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setSelectedImage(acceptedFiles[0]);
      }
    },
  });

  useEffect(() => {
    if (imageId && isOpen) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const { data, response } = await getGallery(imageId);
          if (response.status === 200) {
            setInitialValues({
              name: data.name,
              image: data.image, // Set existing image
              description: data.description,
              author: data.author,
              tags: data.tags,
              validated: data.validated,
            });
          } else {
            Notiflix.Notify.failure("Erreur lors du chargement de l'image");
          }
        } catch (error) {
          console.error("Erreur lors du chargement de l'image :", error);
          Notiflix.Notify.failure("Erreur lors du chargement de l'image");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [imageId, isOpen]);

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    if (selectedImage) {
      formData.append("image", selectedImage); // Append new image if selected
    }
    formData.append("description", values.description);
    formData.append("author", JSON.stringify(values.author));
    formData.append("tags", JSON.stringify(values.tags));
    formData.append("validated", values.validated);

    try {
      const { data, response } = await putImage(imageId, formData);
      if (response.status === 200) {
        Notiflix.Notify.success("Image modifiée avec succès !");
        onSubmit(data);
        onClose();
      } else {
        Notiflix.Notify.failure("Erreur lors de la modification de l'image");
      }
    } catch (error) {
      console.error("Erreur lors de la modification de l'image :", error);
      Notiflix.Notify.failure("Erreur lors de la modification de l'image");
    } finally {
      setLoading(false);
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl">
        <h2 className="text-xl font-bold mb-4">Modifier l'image</h2>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={ImageSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, resetForm }) => (
            <Form className="space-y-4">
              {/* Nom */}
              <div>
                <label htmlFor="name" className="block text-sm font-medium">
                  Nom
                </label>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Image Preview */}
              <div className="mb-4">
                {initialValues.image && !selectedImage && (
                  <img
                    src={initialValues.image}
                    alt="Preview"
                    className="w-full max-h-64 object-contain"
                  />
                )}
                {selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="New Preview"
                    className="w-full max-h-64 object-contain"
                  />
                )}
              </div>

              {/* Image Upload */}
              <div
                {...getRootProps()}
                className="border-2 border-dashed p-4 rounded-md"
              >
                <input {...getInputProps()} type="file" />
                <p className="text-center">
                  Cliquez pour sélectionner une nouvelle image
                </p>
                {selectedImage && (
                  <p className="mt-2">
                    Fichier sélectionné : {selectedImage.name}
                  </p>
                )}
              </div>

              {/* Description */}
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium"
                >
                  Description
                </label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  rows="4"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Auteurs */}
              <div className="flex gap-12">
                <div>
                  <label
                    htmlFor="authors"
                    className="block text-sm font-medium"
                  >
                    Auteurs
                  </label>
                  <div className="flex flex-col space-y-2">
                    {initialAuthors.map((author) => (
                      <div key={author} className="flex items-center space-x-2">
                        <Field
                          type="checkbox"
                          id={`author-${author}`}
                          name="author"
                          value={author}
                          checked={values.author.includes(author.toLowerCase())}
                          onChange={() => {
                            const updatedAuthors = values.author.includes(
                              author,
                            )
                              ? values.author.filter((a) => a !== author)
                              : [...values.author, author];
                            setFieldValue("author", updatedAuthors);
                          }}
                        />
                        <label htmlFor={`author-${author}`}>{author}</label>
                      </div>
                    ))}
                  </div>
                  <ErrorMessage
                    name="author"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Tags */}
                <div>
                  <label htmlFor="tags" className="block text-sm font-medium">
                    Tags
                  </label>
                  <div className="flex flex-col space-y-2">
                    {initialTags.map((tag) => (
                      <div key={tag} className="flex items-center space-x-2">
                        <Field
                          type="checkbox"
                          id={`tag-${tag}`}
                          name="tags"
                          value={values.tags.includes(tag.toLowerCase())}
                          checked={values.tags.includes(tag.toLowerCase())}
                          onChange={() => {
                            const updatedTags = values.tags.includes(tag)
                              ? values.tags.filter((t) => t !== tag)
                              : [...values.tags, tag];
                            setFieldValue("tags", updatedTags);
                          }}
                        />
                        <label htmlFor={`tag-${tag}`}>{tag}</label>
                      </div>
                    ))}
                  </div>
                  <ErrorMessage
                    name="tags"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-4">
                <Buttons
                  type="button"
                  variant="cancel"
                  size="sm"
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                >
                  Annuler
                </Buttons>
                <Buttons type="submit" size="sm" initialIsPending={loading}>
                  Sauvegarder
                </Buttons>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  ) : null;
};

export default EditImageModal;
