import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importation du style de Quill

const ParagraphBlock = ({ content, onChange }) => {
  return (
    <ReactQuill
      value={content}
      onChange={onChange}
      placeholder="Entrez votre texte ici"
      modules={{
        toolbar: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["link"],
          ["clean"],
        ],
      }}
      formats={[
        "header",
        "font",
        "list",
        "bullet",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "color",
        "background",
        "align",
        "link",
      ]}
      className="w-full min-h-[100px] border rounded focus:border-blue-500 outline-none"
    />
  );
};

export default ParagraphBlock;
