import React from "react";
import PropTypes from "prop-types";
import { FaFont, FaItalic, FaUnderline } from "react-icons/fa";
import { FaAlignLeft, FaAlignRight, FaAlignCenter } from "react-icons/fa6";
import { IoIosColorPalette } from "react-icons/io";
const TitleBlock = ({ content, onChange, settings, onSettingsChange }) => {
  const handleFontSizeChange = (e) => {
    onSettingsChange({ ...settings, fontSize: e.target.value });
  };

  const handleStyleChange = (style) => {
    const currentStyles = settings.style || [];
    if (currentStyles.includes(style)) {
      onSettingsChange({
        ...settings,
        style: currentStyles.filter((s) => s !== style),
      });
    } else {
      onSettingsChange({
        ...settings,
        style: [...currentStyles, style],
      });
    }
  };

  const handlePositionChange = (position) => {
    onSettingsChange({ ...settings, position });
  };

  const handleCaseChange = (textCase) => {
    onSettingsChange({ ...settings, textCase });
  };

  const handleGradientToggle = () => {
    onSettingsChange({ ...settings, gradient: !settings.gradient });
  };

  const fontSizeClass = `text-${settings.fontSize || "xl"}`;

  const styleClass =
    settings.style
      ?.map(
        (style) =>
          ({
            bold: "font-bold",
            italic: "italic",
            underline: "underline",
          })[style],
      )
      .join(" ") || "";

  const positionClass =
    {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    }[settings.position] || "text-left";

  const caseClass =
    {
      uppercase: "uppercase",
      lowercase: "lowercase",
      capitalize: "capitalize",
    }[settings.textCase] || "";

  const gradientClass = settings.gradient
    ? "bg-custom-gradient bg-clip-text text-transparent inline-block"
    : "";

  return (
    <section>
      <div className="flex gap-5 items-center flex-wrap justify-between">
        <div className="flex items-center gap-2 mt-2">
          <label>Taille:</label>
          <input
            type="number"
            min="1"
            max="6"
            value={settings.fontSize || 1}
            onChange={handleFontSizeChange}
            className="ml-2 p-1 border border-gray-300 rounded"
            placeholder="Enter size (e.g., 3)"
          />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label>Style:</label>
          <button
            onClick={() => handleStyleChange("bold")}
            className={`p-2 ${settings.style?.includes("bold") ? "bg-blue-500 text-white" : ""}`}
          >
            <FaFont />
          </button>
          <button
            onClick={() => handleStyleChange("italic")}
            className={`p-2 ${settings.style?.includes("italic") ? "bg-blue-500 text-white" : ""}`}
          >
            <FaItalic />
          </button>
          <button
            onClick={() => handleStyleChange("underline")}
            className={`p-2 ${settings.style?.includes("underline") ? "bg-blue-500 text-white" : ""}`}
          >
            <FaUnderline />
          </button>
          <button
            onClick={handleGradientToggle}
            className={`p-2 ${settings.gradient ? "bg-blue-500 text-white" : ""}`}
          >
            <IoIosColorPalette className="text-xl" />
          </button>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label>Position:</label>
          <button
            onClick={() => handlePositionChange("left")}
            className={`p-2 ${settings.position === "left" ? "bg-blue-500 text-white" : ""}`}
          >
            <FaAlignLeft />
          </button>
          <button
            onClick={() => handlePositionChange("center")}
            className={`p-2 ${settings.position === "center" ? "bg-blue-500 text-white" : ""}`}
          >
            <FaAlignCenter />
          </button>
          <button
            onClick={() => handlePositionChange("right")}
            className={`p-2 ${settings.position === "right" ? "bg-blue-500 text-white" : ""}`}
          >
            <FaAlignRight />
          </button>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label>Maj:</label>
          <select
            value={settings.textCase}
            onChange={(e) => handleCaseChange(e.target.value)}
            className="ml-2 p-1 border border-gray-300 rounded"
          >
            <option value="">Normal</option>
            <option value="uppercase">Uppercase</option>
            <option value="lowercase">Lowercase</option>
            <option value="capitalize">Capitalize</option>
          </select>
        </div>
      </div>
      <input
        type="text"
        className={`w-full p-2 ${fontSizeClass} ${styleClass} ${positionClass} ${caseClass} ${gradientClass} border-b-2 border-gray-300 focus:border-blue-500 outline-none`}
        value={content}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Titre de section"
      />
    </section>
  );
};

TitleBlock.propTypes = {
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    fontSize: PropTypes.string,
    style: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.string,
    textCase: PropTypes.string,
    gradient: PropTypes.bool,
  }).isRequired,
  onSettingsChange: PropTypes.func.isRequired,
};

export default TitleBlock;
