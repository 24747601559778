import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import Notiflix from "notiflix";
import Buttons from "../../../../components/Button";
import { postGallery } from "../../../../services/services";

// Validation schema
const ImageSchema = Yup.object().shape({
  name: Yup.string().required("Le nom est requis"),
  image: Yup.mixed().required("L'image est requise"),
  description: Yup.string().required("La description est requise"),
  author: Yup.array().min(1, "Au moins un auteur est requis"),
  tags: Yup.array().min(1, "Au moins un tag est requis"),
  validated: Yup.boolean(),
});

// Predefined tags and authors
const initialTags = [
  "Build",
  "Terraforming",
  "Organique",
  "Novus Odyssey",
  "MineStrator",
];
const initialAuthors = ["Cancri", "Marodeur", "Thomas", "Lison", "NoCheat"];

const ImageForm = ({ onSubmit }) => {
  const [authorsOptions, setAuthorsOptions] = useState(initialAuthors);
  const [tagsOptions, setTagsOptions] = useState(initialTags);
  const [newAuthor, setNewAuthor] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setSelectedImage(acceptedFiles[0]);
      }
    },
  });

  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("image", selectedImage);
    formData.append("description", values.description);
    formData.append("author", JSON.stringify(values.author));
    formData.append("tags", JSON.stringify(values.tags));
    formData.append("validated", values.validated);

    try {
      const { data, response } = await postGallery(formData);
      if (response.status === 201) {
        Notiflix.Notify.success("Image ajoutée avec succès !");
        resetForm();
        setAuthorsOptions(initialAuthors);
        setTagsOptions(initialTags);
        setSelectedImage(null);
        onSubmit({ data });
      } else {
        console.error("Erreur lors de l'ajout de l'image :");
        Notiflix.Notify.failure("Erreur lors de l'ajout de l'image");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'image :", error);
      Notiflix.Notify.failure("Erreur lors de l'ajout de l'image");
    } finally {
      setLoading(false);
    }
  };

  // Handle author addition
  const handleAddAuthor = () => {
    if (newAuthor && !authorsOptions.includes(newAuthor)) {
      setAuthorsOptions([...authorsOptions, newAuthor]);
      setNewAuthor("");
    }
  };

  return (
    <div className="flex flex-col p-6 bg-gray-50 min-h-[70vh]">
      <Formik
        initialValues={{
          name: "",
          image: null,
          description: "",
          author: [],
          tags: [],
          validated: false,
        }}
        validationSchema={ImageSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, resetForm }) => (
          <Form className="flex flex-col bg-white rounded shadow-lg p-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Left Column */}
              <div className="flex flex-col space-y-4">
                {/* Name */}
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom
                  </label>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Image Upload */}
                <div
                  {...getRootProps()}
                  className="cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-md hover:border-blue-500 transition-border duration-500"
                >
                  <input
                    {...getInputProps()}
                    type="file"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setSelectedImage(e.target.files[0]);
                        setFieldValue("image", e.target.files[0]); // Met à jour Formik
                      }
                    }}
                  />
                  <p className="text-center">
                    Cliquez pour sélectionner une image
                  </p>
                  {selectedImage && (
                    <p className="mt-2">
                      Fichier sélectionné : {selectedImage.name}
                    </p>
                  )}
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Description */}
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <Field
                    as="textarea"
                    id="description"
                    name="description"
                    rows="4"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Authors */}
                <div>
                  <label
                    htmlFor="authors"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Auteur(s)
                  </label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        value={newAuthor}
                        onChange={(e) => setNewAuthor(e.target.value)}
                        placeholder="Ajouter un nouvel auteur"
                        className="mt-1 p-2 border border-gray-300 rounded-md flex-grow"
                      />
                      <button
                        type="button"
                        onClick={handleAddAuthor}
                        className="mt-1 px-3 py-2 bg-blue-600 text-white rounded-md"
                      >
                        Ajouter
                      </button>
                    </div>
                    <div className="flex flex-col space-y-2">
                      {authorsOptions.map((author) => (
                        <div
                          key={author}
                          className="flex items-center space-x-2"
                        >
                          <Field
                            type="checkbox"
                            id={`author-${author}`}
                            name="author"
                            value={author}
                            checked={values.author.includes(author)}
                            onChange={() => {
                              const updatedAuthors = values.author.includes(
                                author,
                              )
                                ? values.author.filter((a) => a !== author)
                                : [...values.author, author];
                              setFieldValue("author", updatedAuthors);
                            }}
                            className="mr-2"
                          />
                          <label
                            htmlFor={`author-${author}`}
                            className="text-sm text-gray-700"
                          >
                            {author}
                          </label>
                        </div>
                      ))}
                    </div>
                    <ErrorMessage
                      name="author"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="flex flex-col space-y-4">
                {/* Tags */}
                <div>
                  <label
                    htmlFor="tags"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Tag(s)
                  </label>
                  <div className="flex flex-col space-y-2">
                    {tagsOptions.map((tag) => (
                      <div key={tag} className="flex items-center space-x-2">
                        <Field
                          type="checkbox"
                          id={`tag-${tag}`}
                          name="tags"
                          value={tag}
                          checked={values.tags.includes(tag)}
                          onChange={() => {
                            const updatedTags = values.tags.includes(tag)
                              ? values.tags.filter((t) => t !== tag)
                              : [...values.tags, tag];
                            setFieldValue("tags", updatedTags);
                          }}
                          className="mr-2"
                        />
                        <label
                          htmlFor={`tag-${tag}`}
                          className="text-sm text-gray-700"
                        >
                          {tag}
                        </label>
                      </div>
                    ))}
                    <ErrorMessage
                      name="tags"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-4 mt-4">
              <Buttons
                type="submit"
                initialIsPending={loading}
                size="sm"
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600"
              >
                Soumettre
              </Buttons>
              <Buttons
                type="button"
                initialIsPending={loading}
                variant="cancel"
                size="sm"
                className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-600"
                onClick={() => {
                  resetForm();
                  setAuthorsOptions(initialAuthors);
                  setTagsOptions(initialTags);
                  setSelectedImage(null);
                }}
              >
                Annuler
              </Buttons>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ImageForm;
