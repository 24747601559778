import React from "react";
import {
  Cell,
  Collection,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  useTableOptions,
} from "react-aria-components";

import { customCompare, formatTime } from "../../../../utils/utils";
import { FaDiscord } from "react-icons/fa";
import { LuMail } from "react-icons/lu";

function MyTableHeader({ columns }) {
  let { allowsDragging } = useTableOptions();

  return (
    <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md sticky top-0 z-10">
      {allowsDragging && <Column />}
      <Collection items={columns}>
        {(column) => (
          <Column
            className="p-1 text-white"
            key={column.id}
            isRowHeader={column.isRowHeader}
          >
            {column.name}
          </Column>
        )}
      </Collection>
      <Column />
    </TableHeader>
  );
}

function MyRow({ id, columns, item }) {
  return (
    <Row
      id={id}
      item={item}
      className="border-[#3f3f46] border-2 text-black bg-white"
    >
      {columns.map((col, index) => {
        let cellValue = checkColID(col.id, item);

        return (
          <Cell key={index} className="h-[40px] p-2 text-center">
            {cellValue}
          </Cell>
        );
      })}
    </Row>
  );
}

function checkColID(col, item) {
  switch (col) {
    case "RESPONSE_MODE":
      return (
        <span className="center">
          {item[col] === "discord" && (
            <FaDiscord size={30} className="text-gray-400" />
          )}
          {item[col] === "email" && (
            <LuMail size={30} className="text-gray-400" />
          )}
        </span>
      );
    default:
      return item[col];
  }
}

const ContactTable = (props) => {
  const columns = [
    { name: "ID", id: "_id" },
    { name: "EMAIL", id: "EMAIL", isRowHeader: true },
    { name: "OBJET", id: "OBJECT" },
    { name: "MODE DE RESPONSE", id: "RESPONSE_MODE" },
  ];

  let rows = [...props.data].sort(customCompare);

  return (
    <Table
      aria-label="Minecraft User Table"
      disabledBehavior="selection"
      className="w-full max-w-[100%] max-h-[73vh] rounded-md overflow-hidden"
    >
      <MyTableHeader columns={columns} />
      <TableBody
        className="text-center text-black max-h-[60vh] overflow-y-auto"
        renderEmptyState={() => (
          <div className="p-4 text-center text-gray-400">
            Aucun résultat trouvé.
          </div>
        )}
      >
        {rows
          .slice(
            props.currentPage * props.pageSize - props.pageSize,
            props.currentPage * props.pageSize,
          )
          .map((item, index) => (
            <MyRow key={index} id={index} columns={columns} item={item} />
          ))}
      </TableBody>
    </Table>
  );
};

export default ContactTable;
