import React from "react";
import {
  Button,
  Cell,
  Collection,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  useTableOptions,
} from "react-aria-components";

import { booleanIcon, customCompare, showDate } from "../../../../utils/utils";
import { FaCheckSquare, FaWindowClose } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { webBan } from "../../../../services/services";
import Notiflix from "notiflix";

function MyTableHeader({ columns }) {
  let { allowsDragging } = useTableOptions();

  return (
    <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md sticky top-0 z-10">
      {allowsDragging && <Column />}
      <Collection items={columns}>
        {(column) => (
          <Column
            className="p-1 text-white"
            key={column.id}
            isRowHeader={column.isRowHeader}
          >
            {column.name}
          </Column>
        )}
      </Collection>
      <Column />
    </TableHeader>
  );
}

function MyRow({ id, columns, item }) {
  const handleAction = async (user) => {
    try {
      const { response } = await webBan(user._id);
      if (response.status === 200) {
        Notiflix.Notify.success(`${user.USERNAME} a etait bannie`);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Row
      id={id}
      item={item}
      className="border-[#3f3f46] border-2 text-black bg-white"
    >
      {columns.map((col, index) => {
        let cellValue = checkColID(col.id, item);

        return (
          <>
            <Cell key={index} className="h-[40px] p-2 text-center">
              {cellValue}
            </Cell>
          </>
        );
      })}

      <Cell className="h-[40px] p-2 text-center">
        <Button
          className="bg-red-500 text-white px-3 py-1 rounded font-semibold"
          onPress={async () => {
            await handleAction(item);
          }}
        >
          Bannir
        </Button>
      </Cell>
    </Row>
  );
}

function checkColID(col, item) {
  switch (col) {
    case "createdAt":
      return showDate(item[col]);

    case "ISACTIVE":
      return booleanIcon[item[col]];

    case "ISBANNED":
      return booleanIcon[item[col]];

    case "NOTE":
      return (
        <Button className="ring-1 z-[1] outline-0 ring-[#5b5c64] p-1 mr-1 rounded hover:bg-gray-300 transition-bg duration-500">
          <FiPlus />
        </Button>
      );

    case "USERNAME":
      return (
        <div className="flex items-center gap-5 w-30">
          <img
            className="w-5 h-5 object-cover rounded"
            src={`https://mc-heads.net/avatar/${item.UUID}`}
            alt={"User Skin"}
          />

          <p className="font-bold">{item[col]}</p>
        </div>
      );
    default:
      return item[col];
  }
}

const WebSiteUserTable = (props) => {
  const columns = [
    { name: "ID", id: "_id", isRowHeader: true },
    { name: "NOM", id: "USERNAME", isRowHeader: true },
    { name: "ROLE", id: "ROLE" },
    { name: "ACTIF", id: "ISACTIVE" },
    { name: "BANNI", id: "ISBANNED" },
    { name: "CRÉÉ LE", id: "createdAt" },
    { name: "UUID", id: "UUID" },
    { name: "NOTE", id: "NOTE" },
  ];

  let rows = [...props.data].sort(customCompare);

  return (
    <Table
      aria-label="Minecraft User Table"
      disabledBehavior="selection"
      className="w-full max-w-[100%] max-h-[73vh] rounded-md overflow-hidden"
    >
      <MyTableHeader columns={columns} />
      <TableBody
        className="text-center text-black max-h-[60vh] overflow-y-auto"
        renderEmptyState={() => (
          <div className="p-4 text-center text-gray-400">
            Aucun résultat trouvé.
          </div>
        )}
      >
        {rows
          .slice(
            props.currentPage * props.pageSize - props.pageSize,
            props.currentPage * props.pageSize,
          )
          .map((item, index) => (
            <MyRow key={index} id={index} columns={columns} item={item} />
          ))}
      </TableBody>
    </Table>
  );
};

export default WebSiteUserTable;
