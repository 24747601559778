import { httpRequest } from "../utils/utils";

export function updateZonePoint() {}

const BaseURL = "https://backend.novus-odyssey.fr/api";

// const BaseURL = "http://localhost:30195/api";
// perm a auth
export async function getRoutingPermission() {
  return await httpRequest(`${BaseURL}/permission`, "GET");
}

export async function getRoutingPermissionTabs(data) {
  return await httpRequest(`${BaseURL}/permission/tabs`, "POST", data);
}

export async function isAuthenticated() {
  return await httpRequest(`${BaseURL}/auth`, "GET", null, {});
}
// login logout     0625823199
export async function logout() {
  return await httpRequest(`${BaseURL}/session/logout`, "GET", null, {});
}

export async function login(data) {
  return await httpRequest(`${BaseURL}/session/login`, "POST", data);
}
export async function webBan(id) {
  return await httpRequest(`${BaseURL}/session/ban/${id}`, "GET");
}

// task

export async function getTasks() {
  return await httpRequest(`${BaseURL}/manager/tasks`, "GET");
}

export async function postTask(data) {
  return await httpRequest(`${BaseURL}/manager/tasks/`, "POST", data);
}

export async function putTask(id, data) {
  return await httpRequest(`${BaseURL}/manager/tasks/${id}`, "PUT", data);
}

export async function deleteTask(id) {
  return await httpRequest(`${BaseURL}/manager/deltasks/${id}`, "GET");
}

// gallery
export async function getGallery(id) {
  return await httpRequest(`${BaseURL}/Gallery/${id}`, "GET");
}

export async function getAllGallery() {
  return await httpRequest(`${BaseURL}/Gallery/internal`, "GET");
}

export async function setStatusImage(id, data) {
  return await httpRequest(`${BaseURL}/Gallery/validated/${id}`, "PUT", data);
}

export async function postGallery(data) {
  return await httpRequest(`${BaseURL}/Gallery/`, "POST", data, {
    "Content-Type": "multipart/form-data",
  });
}
export async function putImage(id, data) {
  return await httpRequest(`${BaseURL}/Gallery/modify/${id}`, "PUT", data, {
    "Content-Type": "multipart/form-data",
  });
}
export async function deleteImage(id) {
  return await httpRequest(`${BaseURL}/Gallery/delete/${id}`, "GET");
}
//accouts
export async function getAccounts() {
  return await httpRequest(`${BaseURL}/accounts/`, "GET");
}

export async function getWebAccounts() {
  return await httpRequest(`${BaseURL}/session/accounts`, "GET");
}
//role
export async function getRole() {
  return await httpRequest(`${BaseURL}/role`, "GET");
}

export async function putRole(data) {
  return await httpRequest(`${BaseURL}/role`, "PUT", data);
}

//Id
export async function getArticles() {
  return await httpRequest(`${BaseURL}/article/`, "GET");
}
export async function getArticle(id) {
  return await httpRequest(`${BaseURL}/article/${id}`, "GET");
}
export async function putArticle(id, data) {
  return await httpRequest(`${BaseURL}/article/${id}`, "PUT", data);
}
export async function createArticle(data) {
  return await httpRequest(`${BaseURL}/article/`, "POST", data);
}
export async function deleteArticle(id) {
  return await httpRequest(`${BaseURL}/article/${id}`, "DELETE");
}
export async function publishArticle(id, data) {
  return await httpRequest(`${BaseURL}/article/publish/${id}`, "PUT", data);
}

//map
export async function getMapAudioArea() {
  return await httpRequest(`${BaseURL}/map/audioArea`, "GET");
}

export async function getWarpMarker() {
  return await httpRequest(`${BaseURL}/map/warp`, "GET");
}

export async function getUtilsMarker() {
  return await httpRequest(`${BaseURL}/map/utils`, "GET");
}

export async function getContacts() {
  return await httpRequest(`${BaseURL}/contact`, "GET");
}

export async function getContact(id) {
  return await httpRequest(`${BaseURL}/contact/${id}`, "GET");
}
