import React from "react";
import { getYouTubeEmbedUrl } from "../../../../../utils/utils";

const VideoBlock = ({ content, onChange }) => {
  // Fonction pour générer l'URL intégrée de YouTube

  const embedUrl = getYouTubeEmbedUrl(content);

  return (
    <div>
      <input
        type="text"
        className="w-full p-2 border rounded focus:border-blue-500 outline-none"
        value={content}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Lien de la vidéo YouTube"
      />
      {embedUrl && (
        <iframe
          width="100%"
          height="315"
          src={embedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="mt-4 rounded shadow"
        />
      )}
    </div>
  );
};

export default VideoBlock;
