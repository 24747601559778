import React, { useEffect, useState } from "react";
import { getRole } from "../../../../services/services";
import StaffTable from "./StaffTable";
import RowOptionsMenuTriggerStaff from "./StaffAddModal";

const Staff = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: fetchingData, response } = await getRole();
        if (response.status === 200) {
          setData([...fetchingData.data]);
        }
      } catch (error) {
        if (error.response) {
          return;
        } else {
          console.log("fetch error", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = (updatedUser) => {
    setData((prevData) =>
      prevData.map((user) =>
        user.UUID === updatedUser.UUID ? updatedUser : user,
      ),
    );
  };

  return (
    <section>
      <section className="bg-gray-100 w-full p-2 overflow-hidden">
        <div className="flex items-center justify-end">
          <RowOptionsMenuTriggerStaff data={data} onUpdate={handleUpdate} />
        </div>
        <section className="shadowCustom w-full h-[75vh] mt-2 rounded max-h-[73vh] !overflow-scroll">
          <StaffTable data={data} loading={loading} />
        </section>
      </section>
    </section>
  );
};

export default Staff;
