import React, { useEffect, useState } from "react";
import { Button, Input, Label, SearchField } from "react-aria-components";
import { useFilter } from "react-aria";

import { getContacts } from "../../../../services/services";
import ContactTable from "./ContactTable";

const ContactManager = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeOptions = [25, 50, 75];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, response } = await getContacts();

        if (response.status === 200) {
          setData(data.data);
        }
      } catch (error) {
        if (error.response) return;
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  let { contains } = useFilter({
    sensitivity: "base",
  });

  let matchedComposers = data.filter((composer) => {
    const matchesSearch =
      value.length > 3
        ? contains(composer.EMAIL.toLowerCase().trim(), value.toLowerCase())
        : true;

    return matchesSearch;
  });

  const totalItems = matchedComposers.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    if (page === 0) return setCurrentPage(1);
    if (page > totalPages) return setCurrentPage(totalPages);
    setCurrentPage(page);
  };

  return (
    <section className="bg-gray-100 w-full p-2 overflow-hidden  mx-auto">
      <div className="flex gap-3 items-center justify-between w-full">
        <SearchField className="flex items-center p-2">
          <Label className="mr-2 text-black font-semibold">Rechercher</Label>
          <Input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setCurrentPage(1);
            }}
            className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            placeholder="Entrez le nom d'un media..."
          />
          <Button
            onPress={() => setValue("")}
            className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
            aria-label="Clear search"
          >
            ✕
          </Button>
        </SearchField>

        <div className="flex items-center gap-3 text-white">
          <Label className="font-semibold text-black text-right pl-2">
            Résultat:{" "}
          </Label>
          <p className="center text-black px-2 rounded-md border-[#8f8f8f] border p-2">
            {matchedComposers.length} / {data.length} Medias
          </p>
        </div>
      </div>
      <section className=" w-full h-[75vh] mt-2 rounded max-h-[73vh] !overflow-scroll">
        <ContactTable
          data={matchedComposers}
          loading={loading}
          pageSize={pageSize}
          currentPage={currentPage}
        />
      </section>
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-2 text-back">
          <p className="">{`Page `}</p>
          <Input
            className="bg-transparent w-8 text-center border border-black rounded outline-none"
            value={currentPage}
            onChange={(e) => {
              handlePageChange(Number(e.target.value));
            }}
          />
          <p className="">{` / ${totalPages}`}</p>
        </div>

        <Button
          onPress={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="text-black px-2 rounded-md border-[#8f8f8f] border"
        >
          Précédent
        </Button>
        <Button
          onPress={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="text-black px-2 rounded-md border-[#8f8f8f] border"
        >
          Suivant
        </Button>
      </div>
    </section>
  );
};

export default ContactManager;
