import { createSlice } from "@reduxjs/toolkit";

export const errorSlice = createSlice({
  name: "error",
  initialState: {
    fetchingError: {},
    tabsName: "",
  },
  reducers: {
    setFetchError: (state, action) => {
      return { ...state, fetchingError: action.payload };
    },
    getFetchError: (state) => {
      return { ...state.fetchingError };
    },
    removeFetchError: () => {
      return { fetchingError: {} };
    },
    setTabsError: (state, action) => {
      return { ...state, tabsName: action.payload };
    },
    getError: (state) => {
      return { ...state };
    },
    removeTabsError: () => {
      return { tabsName: "" };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTabsError,
  getError,
  removeTabsError,
  setFetchError,
  removeFetchError,
  getFetchError,
} = errorSlice.actions;

export default errorSlice.reducer;
