import React, { useState } from "react";
import {
  DialogTrigger,
  Button,
  Modal,
  Dialog,
  Heading,
} from "react-aria-components";
import { FiPlus } from "react-icons/fi";

const RowOptionsMenuTrigger = (props) => {
  const { image } = props.data;

  const [buttonMessage, setButtonMessage] = useState(<FiPlus />);
  const [modalData, setModalData] = useState();

  return (
    <DialogTrigger>
      <Button
        onPress={() => {
          setModalData(props.data);
        }}
        className="ring-1 z-[1] outline-0 ring-[#5b5c64] p-1 mr-1 rounded hover:bg-gray-500 transition-bg duration-500"
      >
        {buttonMessage}
      </Button>

      <Modal
        className="fixed top-0 left-0 right-0 bottom-0 z-[1000] bg-[#212121] p-2 rounded overflow-hidden"
        isOpen={modalData !== undefined} // Control visibility based on modalData
        onOpenChange={(isOpen) => {
          if (!isOpen) setModalData(undefined); // Clear modalData when closed
        }}
      >
        {modalData && (
          <Dialog className="w-full overflow-hidden">
            {({ close }) => (
              <div className="relative w-full h-[98vh] flex  ">
                <Heading
                  slot="title"
                  className="text-[25px] px-2 py-1 text-white font-bold uppercase"
                >
                  {/* Add title here if needed */}
                </Heading>

                <section className=" w-full h-full flex items-center justify-center gap-4 w-full text-white p-2 object-cover">
                  <img
                    className="w-[100%]] h-full object-cover "
                    src={image}
                    alt="test"
                  />
                </section>

                <Button
                  className="absolute top-5 shadow font-semibold right-5 px-3 py-1 text-white rounded bg-red-700 hover:bg-red-500 transition-bg duration-500"
                  onPress={close}
                >
                  Fermer
                </Button>
              </div>
            )}
          </Dialog>
        )}
      </Modal>
    </DialogTrigger>
  );
};

export default RowOptionsMenuTrigger;
