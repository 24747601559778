import React from "react";
import {
  Cell,
  Collection,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  useTableOptions,
} from "react-aria-components";
import { booleanIcon } from "../../../../utils/utils";

function MyTableHeader({ columns }) {
  let { allowsDragging } = useTableOptions();

  return (
    <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md sticky top-0 z-10">
      {allowsDragging && <Column />}
      <Collection items={columns}>
        {(column) => (
          <Column
            className="font-exo p-1 text-white"
            key={column.id}
            isRowHeader={column.isRowHeader}
          >
            {column.name}
          </Column>
        )}
      </Collection>
      <Column />
    </TableHeader>
  );
}

function MyRow({ id, columns, item }) {
  return (
    <Row
      id={id}
      item={item}
      className="border-[#3f3f46] border-2 text-black bg-white"
    >
      {columns.map((col, index) => {
        let cellValue = checkColID(col.id, item);

        return (
          <>
            <Cell key={index} className="h-[40px] p-2 text-center">
              {cellValue}
            </Cell>
          </>
        );
      })}
    </Row>
  );
}

function checkColID(col, item) {
  switch (col) {
    case "USERNAME":
      return (
        <div className="flex items-center gap-5 w-30">
          <img
            className="w-8 h-8 object-cover rounded shadowCustom"
            src={`https://mc-heads.net/avatar/${item.UUID}`}
            alt={"User Skin"}
          />

          <p className="font-[800] font-exo">{item[col]}</p>
        </div>
      );
    case "WEB_ROLE": {
      return item[col] !== "N/A" ? item[col] : booleanIcon[false];
    }
    case "LOGIN_EXISTS":
      return booleanIcon[item[col]];

    case "ACCOUNTS_EXISTS":
      return booleanIcon[item[col]];
    default:
      return item[col];
  }
}

const StaffTable = (props) => {
  const columns = [
    { name: "NOM", id: "USERNAME", isRowHeader: true },
    { name: "WEB ROLE", id: "WEB_ROLE" },
    { name: "MC ROLE", id: "MC_ROLE" },
    { name: "UUID", id: "UUID", isRowHeader: true },
    { name: "PROFILE WEB", id: "LOGIN_EXISTS" },
    { name: "PROFILE MINECRAFT", id: "ACCOUNTS_EXISTS" },
  ];

  let rows = [...props.data];

  const filteredRows = rows.filter((item) => {
    return (
      !["JOUEUR", "VIP", "N/A"].includes(item.WEB_ROLE) ||
      !["JOUEUR", "VIP", "N/A"].includes(item.MC_ROLE)
    );
  });

  return (
    <Table
      aria-label="Minecraft User Table"
      disabledBehavior="selection"
      className=" w-full max-w-[100%] max-h-[73vh] rounded-md overflow-hidden"
    >
      <MyTableHeader columns={columns} />
      <TableBody
        className="text-center text-black max-h-[60vh] overflow-y-auto"
        renderEmptyState={() => (
          <div className="p-4 text-center text-gray-400">
            Aucun résultat trouvé.
          </div>
        )}
      >
        {filteredRows.map((item, index) => (
          <MyRow key={index} id={index} columns={columns} item={item} />
        ))}
      </TableBody>
    </Table>
  );
};

export default StaffTable;
