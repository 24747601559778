import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FRONTEND_ERRORS from "../utils/ErrorsCode";
import Notiflix from "notiflix";
import { useSelector } from "react-redux";

function RouteGuard({ children, checkFn }) {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const allowedRoutes = useSelector((state) => state.route.FetchingRoute).map(
    (el) => el.link,
  );
  const check = isAuthorized && allowedRoutes.includes(pathname);

  useEffect(() => {
    (async () => {
      try {
        const isAllowed = await checkFn();

        if (isAllowed.valid) {
          setIsAuthorized(true);
        } else {
          const errorInfo = FRONTEND_ERRORS.getErrorInfo(
            isAllowed.response.data.code,
          );
          navigate("/error", {
            state: {
              code: isAllowed.response.data.code,
              status: errorInfo.httpCode,
              message: errorInfo.message,
            },
          });
        }
      } catch (error) {
        if (error.response) {
          const errorInfo = FRONTEND_ERRORS.getErrorInfo(
            error.response.data.code,
          );

          navigate("/error", {
            state: {
              code:
                error.response.data.code ||
                FRONTEND_ERRORS.CODES.ACCESS_DENY.message,
              status: errorInfo.httpCode,
              message: errorInfo.message,
            },
          });
        } else {
          const errorInfo = FRONTEND_ERRORS.getErrorInfo("INTERNAL_ERROR");

          navigate("/error", {
            state: {
              code: "INTERNAL_ERROR",
              status: errorInfo.httpCode,
              message: errorInfo.message,
            },
          });
        }
      } finally {
        Notiflix.Loading.remove();
        setLoading(false);
      }
    })();
  }, [checkFn, navigate, allowedRoutes]);

  if (loading) return Notiflix.Loading.circle("Chargement...");

  if (isAuthorized) {
    return children;
  } else {
    console.log("null");
    return null;
  }
}
RouteGuard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  checkFn: PropTypes.func.isRequired,
};

export default RouteGuard;
