import { getContrastingColors } from "../../../../utils/utils";

export const createGridLines = (step, max) => {
  const lines = [];

  // Horizontal lines
  for (let i = 0; i <= max; i += step) {
    lines.push([
      [i, 0], // Start from the left
      [i, max], // End at the right
    ]);
  }

  // Vertical lines
  for (let i = 0; i <= max; i += step) {
    lines.push([
      [0, i], // Start from the top
      [max, i], // End at the bottom
    ]);
  }

  return lines;
};

export const convertCoordinates = (x, z) => {
  return [1024 - z, x];
};
export const reverseConvertCoordinates = (lat, lng) => {
  return {
    x: lng,
    z: 1024 - lat,
  };
};

export const convertMarkers = (markers) => {
  return markers.map((marker) => ({
    position: convertCoordinates(marker.x, marker.z),
    text: marker.text,
  }));
};

export const getRandomColor = (zone) => {
  const colors = getContrastingColors(zone.length);
  return colors[Math.floor(Math.random() * colors.length)];
};

export default {
  createGridLines,
  convertCoordinates,
  reverseConvertCoordinates,
  getRandomColor,
  convertMarkers,
};
