import React, { useState, useEffect } from "react";
import {
  DialogTrigger,
  Button,
  Modal,
  Dialog,
  Heading,
  SearchField,
  Label,
  Input,
  Table,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell,
} from "react-aria-components";
import Buttons from "../../../../components/Button";
import { IoAddCircle } from "react-icons/io5";
import { useFilter } from "react-aria";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoCopy } from "react-icons/io5";
import { putRole } from "../../../../services/services";
import Notiflix from "notiflix";

const roles = [
  "JOUEUR",
  "VIP",
  "HELPER",
  "STAFF",
  "MODERATEUR",
  "ADMINISTRATEUR",
  "DEVELOPPEUR",
];

const RowOptionsMenuTriggerStaff = (props) => {
  let modalData = [...props.data];
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState({});
  let { contains } = useFilter({
    sensitivity: "base",
  });

  let matchedComposers = modalData.filter((composer) => {
    return value.length > 3
      ? contains(composer.USERNAME.toLowerCase().trim(), value.toLowerCase()) ||
          contains(
            composer.UUID.toLowerCase().trim(),
            value.toLowerCase().trim(),
          )
      : composer;
  });
  const submitRole = async () => {
    const { response } = await putRole(selected);
    if (response.status === 200) {
      props.onUpdate(response.data.data);
      Notiflix.Notify.success("Rôle mise a jour!");
    }
  };

  const updateRole = async (roleType, newRole) => {
    const updatedUser = { ...selected, [roleType]: newRole };
    setSelected(updatedUser);
  };

  const RoleSelector = ({ role, onUpdateRole, roleType, onSubmit }) => {
    const [selectedRole, setSelectedRole] = useState(role);

    useEffect(() => {
      setSelectedRole(role);
    }, [role]);

    if (selectedRole === "N/A" || selectedRole === "SUPER_ADMIN") return;

    const handleRoleClick = async (newRole) => {
      setSelectedRole(newRole);
      await onUpdateRole(roleType, newRole);
    };

    return (
      <section className="flex flex-col gap-2">
        {roleType === "WEB_ROLE" && (
          <Heading>Configuration du rôle Web</Heading>
        )}
        {roleType === "MC_ROLE" && (
          <Heading>Configuration du rôle Minecraft</Heading>
        )}
        <div className="w-64 p-4 border rounded-lg">
          <p className="font-bold mb-4">Rôle: {selectedRole || "N/A"}</p>
          <div className="space-y-2">
            {roles.map((role) => (
              <Button
                key={role}
                onPress={() => handleRoleClick(role)}
                className={({ isFocusVisible, isPressed }) => `
                w-full p-2 rounded outline-none
                ${
                  selectedRole === role
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 hover:bg-gray-300"
                }
                ${isFocusVisible ? "ring-2 ring-offset-2 ring-blue-500" : ""}
                ${isPressed ? "transform scale-95" : ""}
              `}
              >
                {role}
              </Button>
            ))}
          </div>
        </div>
      </section>
    );
  };

  return (
    <DialogTrigger>
      <Buttons
        variant="minimal_outline"
        withIcons={true}
        iconsPosition="left"
        icon={<IoAddCircle className="text-3xl" />}
        className="font-exo font-[700] uppercase pr-3 w-full"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        promouvoir un utilisateur
      </Buttons>

      <Modal
        className="font-exo center fixed top-0 left-0 right-0 bottom-0 z-[1000] bg-[#000000e6] p-2 overflow-hidden"
        isOpen={isOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsOpen(false);
        }}
      >
        {modalData && (
          <Dialog className="font-exo w-[60%] h-[90%] rounded bg-white opacity-1 text-black">
            {({ close }) => (
              <div className="w-full h-full flex flex-col text-center justify-between">
                <div className="flex items-center justify-between px-4 py-5">
                  <Heading
                    slot="title"
                    className="text-xl text-NO_DarkGray font-[900] uppercase"
                  >
                    Ajouter des permissions à un utilisateur
                  </Heading>
                  <Buttons
                    variant="danger"
                    size="sm"
                    onClick={close}
                    className="uppercase font-bold"
                  >
                    Fermer
                  </Buttons>
                </div>

                <section className="font-exo flex-1 w-full h-5/6 p-2 flex gap-2 items-center justify-center">
                  <section className="items-center justify-between flex-col bg-gray-200 shadow h-full w-[35%]">
                    <Heading
                      slot="title"
                      className="text-xl font-bold uppercase pt-2 text-black"
                    >
                      Utilisateurs
                    </Heading>
                    <SearchField className="center p-2">
                      <div className="flex items-center">
                        <Input
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                          placeholder="Rechercher un utilisateur..."
                        />
                        <Button
                          onPress={() => setValue("")}
                          className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
                          aria-label="Clear search"
                        >
                          ✕
                        </Button>
                      </div>
                    </SearchField>

                    <Table className="w-full h-auto text-white overflow-hidden">
                      <TableHeader className="border-b">
                        <Column
                          isRowHeader
                          className="text-center pl-2"
                        ></Column>
                        <Column
                          isRowHeader
                          className="text-center pl-2"
                        ></Column>
                      </TableHeader>
                      <TableBody className="table-auto text-center overflow-scroll">
                        {matchedComposers.map((item, index) => (
                          <Row
                            key={index}
                            className="text-black text-center pb-2 border-b border-neutral-500"
                            id={index}
                          >
                            <Cell>
                              <div className="flex items-center gap-5 w-30">
                                <img
                                  className="w-6 h-6 object-cover rounded ml-2 shadowCustom"
                                  src={`https://mc-heads.net/avatar/${item.UUID}`}
                                  alt={"User Skin"}
                                />
                                <p className="font-bold center w-full font-exo">
                                  {item.USERNAME}
                                </p>
                              </div>
                            </Cell>
                            <Cell>
                              <Button
                                className="bg-white rounded-md border-[#8f8f8f] border p-1"
                                onPress={() => {
                                  setSelected({
                                    USERNAME: item.USERNAME,
                                    UUID: item.UUID,
                                    WEB_ROLE: item.WEB_ROLE, // Resetting the roles
                                    MC_ROLE: item.MC_ROLE, // Resetting the roles
                                  });
                                }}
                              >
                                <FaLongArrowAltRight />
                              </Button>
                            </Cell>
                          </Row>
                        ))}
                      </TableBody>
                    </Table>
                  </section>
                  <section className="bg-white border border-gray-300 rounded h-full w-[60%]">
                    <Heading
                      slot="title"
                      className="text-xl font-bold uppercase pt-2 text-black"
                    >
                      Assigner un role
                    </Heading>
                    <hr className="border-black w-[90%] m-auto" />
                    <section className="flex items-start justify-between flex-col gap-5 p-4">
                      <div className="text-black flex gap-2 items-center w-full justify-between">
                        <p className="font-bold">UUID:</p>
                        <div className="center gap-5">
                          {selected.UUID ? (
                            <img
                              className="w-8 h-8 object-cover rounded shadowCustom"
                              src={`https://mc-heads.net/avatar/${selected.UUID}`}
                              alt={"User Skin"}
                            />
                          ) : (
                            <div className="w-5 h-5 bg-gray-700 rounded shadow-2xl"></div>
                          )}
                          <p className="text-black">{selected.UUID || "N/A"}</p>
                          <Button
                            className="text-gray-800 hover:text-gray-500 transition-text duration-500 focus:text-green-600"
                            onPress={() =>
                              navigator.clipboard.writeText(selected.UUID)
                            }
                          >
                            <IoCopy className="text-xl" />
                          </Button>
                        </div>
                      </div>
                      <div className="text-black flex gap-2 items-center justify-between w-full">
                        <p className="font-bold">USERNAME:</p>
                        <p className="text-black">
                          {selected.USERNAME || "N/A"}
                        </p>
                      </div>
                      <div className="center gap-2 w-full h-[400px]">
                        <RoleSelector
                          role={selected.WEB_ROLE}
                          roleType="WEB_ROLE"
                          onUpdateRole={updateRole}
                        />
                        <RoleSelector
                          role={selected.MC_ROLE}
                          roleType="MC_ROLE"
                          onUpdateRole={updateRole}
                        />
                      </div>
                      {/* Correcting the onPress call */}
                      <Button
                        onPress={submitRole}
                        className="mt-4 bg-blue-500 text-white p-2 rounded font-semibold"
                      >
                        Promouvoir
                      </Button>
                    </section>
                  </section>
                </section>
              </div>
            )}
          </Dialog>
        )}
      </Modal>
    </DialogTrigger>
  );
};

export default RowOptionsMenuTriggerStaff;
// TODO faire les requetes -
