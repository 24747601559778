import React from "react";
import { Button } from "react-aria-components";
import { FaChevronLeft, FaChevronRight, FaWindowClose } from "react-icons/fa";
const ArticleLightbox = ({
  images,
  currentIndex,
  onClose,
  onNext,
  onPrevious,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
      <div className="relative w-full h-full max-w-4xl max-h-4xl">
        <img
          src={images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
          className="w-full h-full object-contain"
        />
        <Button
          onPress={onClose}
          className="absolute top-4 right-4 text-white hover:text-gray-300"
        >
          <FaWindowClose className="text-2xl" />
        </Button>
        <Button
          onPress={onPrevious}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-300"
        >
          <FaChevronLeft className="text-2xl" />
        </Button>
        <Button
          onPress={onNext}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-300"
        >
          <FaChevronRight className="text-2xl" />
        </Button>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white">
          {currentIndex + 1} / {images.length}
        </div>
      </div>
    </div>
  );
};

export default ArticleLightbox;
