import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaTrashAlt } from "react-icons/fa";
import { TbDragDrop2 } from "react-icons/tb";
import { FaArrowLeft } from "react-icons/fa";
import ArticlePreview from "./ArticlePreview";
import TitleBlock from "./ArticleComponents/TitleBlock";
import ParagraphBlock from "./ArticleComponents/ParagraphBlock";
import ImageBlock from "./ArticleComponents/ImageBlock";
import ImageGalleryBlock from "./ArticleComponents/ImageGalleryBlock";
import VideoBlock from "./ArticleComponents/VideoBlock";
import ActionButtonBlock from "./ArticleComponents/ActionButtonBlock";
import BannerBlock from "./ArticleComponents/BannerBlock";
import Buttons from "../../../../components/Button";
import { createArticle } from "../../../../services/services";
import Notiflix from "notiflix";
import ArticleMediaSelector from "./ArticleMediaSelector";
import { getValidationIcon } from "../../../../utils/utils";

const BlockTypes = {
  TITLE: "Titre",
  // BANNER: "Bannière",
  PARAGRAPH: "Paragraphe",
  IMAGE: "Image seule",
  IMAGE_BLOCK: "Block image",
  VIDEO: "Vidéo",
  ACTION_BUTTON: "Bouton d'action",
};

const BlockRenderer = ({ block, onChange, onSettingsChange }) => {
  switch (block.type) {
    case "TITLE":
      return (
        <TitleBlock
          content={block.content}
          onChange={onChange}
          settings={block.settings || {}}
          onSettingsChange={onSettingsChange}
        />
      );
    case "PARAGRAPH":
      return <ParagraphBlock content={block.content} onChange={onChange} />;
    case "IMAGE":
      return <ImageBlock content={block.content} onChange={onChange} />;
    case "IMAGE_BLOCK":
      return <ImageGalleryBlock content={block.content} onChange={onChange} />;
    case "VIDEO":
      return <VideoBlock content={block.content} onChange={onChange} />;
    case "ACTION_BUTTON":
      return (
        <ActionButtonBlock
          content={block.content}
          onChange={onChange}
          settings={block.settings || {}}
          onSettingsChange={onSettingsChange}
        />
      );
    // case "BANNER":
    //   return <BannerBlock content={block.content} onChange={onChange} />;
    default:
      return null;
  }
};

const BlogEditor = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [banner, setBanner] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // default to today's date
  const [description, setDescription] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [status, setStatus] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const maxLength = 200;

  const resetForm = () => {
    setTitle("");
    setAuthor("");
    setBanner("");
    setDate(new Date().toISOString().split("T")[0]);
    setDescription("");
    setBlocks([]);
    setStatus("Brouillon");
  };

  const addBlock = (type) => {
    setBlocks([
      ...blocks,
      {
        id: `block-${Date.now()}`,
        type,
        content: type === "IMAGE_BLOCK" ? [] : "",
      },
    ]);
  };

  const updateBlockSettings = (id, settings) => {
    const newBlocks = blocks.map((block) =>
      block.id === id ? { ...block, settings } : block,
    );
    setBlocks(newBlocks);
  };

  const updateBlock = (index, content) => {
    const newBlocks = [...blocks];
    newBlocks[index].content = content;
    setBlocks(newBlocks);
  };

  const removeBlock = (index) => {
    const newBlocks = blocks.filter((_, i) => i !== index);
    setBlocks(newBlocks);
  };

  const publishArticle = async () => {
    const articleData = {
      TITLE: title,
      AUTHOR: author,
      BANNER: banner,
      DESCRIPTION: description,
      BLOCKS: blocks,
      STATUS: true,
    };

    try {
      const { response } = await createArticle(articleData);
      if (response.status === 201) {
        Notiflix.Notify.success("Id publier");
        setStatus(true);
        resetForm();
      }
    } catch (e) {
      console.log(e);
      Notiflix.Notify.failure("Erreur l'ors de la publication");
    }
  };

  const saveArticle = async () => {
    const articleData = {
      TITLE: title,
      AUTHOR: author,
      BANNER: banner,
      DESCRIPTION: description,
      BLOCKS: blocks,
      STATUS: false,
    };

    try {
      const { response } = await createArticle(articleData);
      if (response.status === 201) {
        Notiflix.Notify.success("Id enregistrés");
        setStatus(false);
        resetForm();
      }
    } catch (e) {
      console.log(e);
      Notiflix.Notify.failure("Erreur l'ors de la publication");
    }
  };

  const togglePreviewMode = () => {
    setPreviewMode(!previewMode);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(blocks);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    setBlocks(items);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-white p-4 flex justify-between items-center shadow-md">
        <div className="flex gap-5">
          <Buttons
            onClick={publishArticle}
            variant="success"
            size="medium"
            className="mr-2"
          >
            PUBLIER L'ARTICLE
          </Buttons>
          <Buttons onClick={saveArticle} variant="primary" size="medium">
            ENREGISTRER
          </Buttons>
          <Buttons onClick={togglePreviewMode} variant="retro" size="medium">
            {previewMode ? (
              <span className="flex items-center gap-5 uppercase">
                <FaArrowLeft className="mb-1" /> retour
              </span>
            ) : (
              "PREVISUALISER"
            )}
          </Buttons>
          <Buttons onClick={resetForm} variant="danger" size="medium">
            Reset
          </Buttons>
        </div>
        <div className="flex gap-5 text-right">
          Status de l'article :
          <span className="font-bold text-red-500">
            {getValidationIcon(status)}
          </span>
        </div>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {!previewMode ? (
          <>
            <aside className="w-64 bg-gray-200 p-4 overflow-y-auto">
              <h2 className="font-bold mb-4 text-lg">Ajouter un bloc</h2>
              {Object.entries(BlockTypes).map(([key, value]) => (
                <button
                  key={key}
                  onClick={() => addBlock(key)}
                  className="block w-full bg-white p-2 mb-2 text-left hover:bg-gray-100 transition rounded"
                >
                  {value}
                </button>
              ))}
            </aside>

            <main className="flex-1 p-4 overflow-y-auto bg-white shadow-inner">
              <div className="max-w-3xl mx-auto">
                <h3 className="my-3 uppercase font-semibold font-exo underline">
                  Configuration de l'article :
                </h3>
                <section className="mb-10 p-2 border border-gray-300 ">
                  <div>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Ajouter un titre"
                      className="w-full p-2 text-3xl font-bold mb-4 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                    />
                    <div className="flex items-center justify-between mb-4 text-gray-600">
                      <input
                        type="text"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        placeholder="Nom de l'auteur"
                        className="mr-4 p-1 border-b border-gray-300 focus:border-blue-500 outline-none"
                      />
                      <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="p-1 border-b border-gray-300 focus:border-blue-500 outline-none"
                      />
                      <div className="flex flex-col items-center justify-center">
                        <input
                          type="text"
                          accept="image/*"
                          value={banner}
                          className="p-1 border-b border-gray-300 focus:border-blue-500 outline-none"
                        />
                        <ArticleMediaSelector
                          onImageSelected={(url) => {
                            setBanner(url);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength={maxLength}
                      placeholder="description pour la carte"
                      className=" w-full p-1 border border-gray-300 border-b-2 focus:border-b-blue-500 outline-none"
                    ></textarea>
                    <p className="flex justify-end">
                      {description.length} / {maxLength}
                    </p>
                  </div>
                </section>
                <h3 className="my-3 uppercase font-semibold font-exo underline">
                  Composition de l'article :
                </h3>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="space-y-4 "
                      >
                        {blocks.map((block, index) => (
                          <Draggable
                            key={block.id}
                            draggableId={block.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-6 p-4 border w-full rounded shadow-sm bg-white"
                              >
                                <BlockRenderer
                                  block={block}
                                  onChange={(content) =>
                                    updateBlock(index, content)
                                  }
                                  onSettingsChange={(settings) =>
                                    updateBlockSettings(block.id, settings)
                                  }
                                />
                                <button
                                  onClick={() => removeBlock(index)}
                                  className="flex items-center gap-2 mt-2 text-red-500 hover:text-red-600 transition"
                                >
                                  <FaTrashAlt /> Supprimer le bloc
                                </button>
                                <TbDragDrop2
                                  className="absolute top-0 left-0 text-gray-500 cursor-move"
                                  data-drag-handle
                                />
                                <TbDragDrop2
                                  className="absolute bottom-0 right-0 text-gray-500 cursor-move rotate-180"
                                  data-drag-handle
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </main>
          </>
        ) : (
          //     blocks.length !== 0 &&
          <ArticlePreview
            title={title}
            date={date}
            description={description}
            author={author}
            banner={banner}
            data={blocks}
          />
        )}
      </div>
    </div>
  );
};

export default BlogEditor;
