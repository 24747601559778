import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { getUsername, showDate } from "../../../utils/utils";
import { MdEditDocument } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { deleteTask, getTasks, putTask } from "../../../services/services";
import { FaRegTrashCan } from "react-icons/fa6";
import ConfirmationModal from "./ConfirmationModal";
import {
  Button,
  OverlayArrow,
  Tooltip,
  TooltipTrigger,
} from "react-aria-components";
const predefinedMembers = [
  "Thomas",
  "Lison",
  "Axel",
  "Marodeur",
  "Cancri",
  "Delta",
  "NoCheat",
];

const TaskManager = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [newStatus, setNewStatus] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [members, setMembers] = useState([]);
  const [availableMembers, setAvailableMembers] = useState(predefinedMembers);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({});
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await getTasks();
        const formattedTasks = response.data.map((task) => ({
          _id: task._id,
          title: task.TITLE,
          status: task.STATUS || 0,
          description: task.DESCRIPTION,
          type: task.TYPE || null,
          createdAt: new Date(task.createdAt),
          borderColor: getBorderColor(task.TYPE),
          priority: task.PRIORITY || 1,
          notes: task.NOTES || [],
          members: task.MEMBERS || [],
        }));

        setTasks(formattedTasks.sort((a, b) => b.priority - a.priority));
      } catch (error) {
        console.error("Erreur lors de la récupération des tâches :", error);
      }
    };
    fetchTasks();
    // const interval = setInterval(fetchTasks, 30 * 60); // Rafraîchit toutes les 5 secondes
    //
    // return () => clearInterval(interval); // Nettoie l'intervalle quand le composant est démonté
  }, []);

  const getBorderColor = (type) => {
    switch (type) {
      case "Builder":
        return "border-blue-500";
      case "Dev":
        return "border-yellow-500";
      case "Tech":
        return "border-orange-500";
      case "Autre":
        return "border-pink-900";
      default:
        return "border-gray-900";
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 5:
        return "text-red-500 font-black";
      case 4:
        return "text-orange-500 font-bold";
      case 3:
        return "text-yellow-500 font-semibold";
      case 2:
        return "text-blue-500 font-medium";
      case 1:
        return "text-green-500 font-normal";
      default:
        return "text-gray-400 font-light";
    }
  };

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case 5:
        return "Urgent";
      case 4:
        return "Important";
      case 3:
        return "Moyen";
      case 2:
        return "Faible";
      case 1:
        return "Très faible";
      default:
        return "N/A";
    }
  };

  const formatStatusText = (status) => {
    if (status === 100) {
      return (
        <span className="text-green-700 capitalize font-bold ">Terminée</span>
      );
    } else if (status === 0) {
      return (
        <span className="text-red-700 capitalize font-black ">À faire</span>
      );
    } else {
      return (
        <span className="text-orange-500 capitalize font-semibold ">
          En cours - {status}%
        </span>
      );
    }
  };

  const handleDragStart = (e, taskId) => {
    e.dataTransfer.setData("taskId", taskId);
  };

  const handleDrop = (e, newCategory) => {
    e.preventDefault();
    const taskId = e.dataTransfer.getData("taskId");

    const draggedTask = tasks.find((task) => task._id === taskId);

    if (!draggedTask) return;

    if (draggedTask.status === 100 && newCategory.status !== 100) {
      setCurrentCategory(newCategory);
      setShowProgressModal(true);
      setSelectedTask(draggedTask);
    } else {
      let updatedTask = {
        ...draggedTask,
        type: newCategory.type || draggedTask.type,
      };

      if (newCategory.status === 100) {
        updatedTask.status = 100; // Setting to "Terminée"
      } else if (newCategory.status === 0) {
        updatedTask.status = 0; // Setting to "À faire"
      } else {
        updatedTask.status = draggedTask.status || 1; // Default to "En cours" if not in the completed category
      }

      setTasks((prevTasks) =>
        prevTasks.map((task) => (task._id === taskId ? updatedTask : task)),
      );

      updateTaskOnServer(updatedTask);
    }
  };

  const updateTaskOnServer = async (updatedTask) => {
    try {
      await putTask(updatedTask._id, {
        TITLE: updatedTask.title,
        STATUS: updatedTask.status,
        TYPE: updatedTask.type,
        DESCRIPTION: updatedTask.description,
        PRIORITY: updatedTask.priority,
        NOTES: updatedTask.notes,
        MEMBERS: updatedTask.members,
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  };

  const handleStatusChange = (e) => {
    const newStatusValue = parseInt(e.target.value, 10);
    setNewStatus(newStatusValue);

    if (selectedTask) {
      setSelectedTask({ ...selectedTask, status: newStatusValue });
    }
  };

  const handleProgressModalConfirm = () => {
    if (selectedTask) {
      let updatedTask = {
        ...selectedTask,
        status: newStatus,
      };

      if (newStatus === 0) {
        updatedTask = {
          ...updatedTask,
          status: 0,
          type: null,
        };
      } else {
        updatedTask = {
          ...updatedTask,
          type: currentCategory.type,
        };
      }

      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === selectedTask._id ? updatedTask : task,
        ),
      );

      updateTaskOnServer(updatedTask);
      setShowProgressModal(false);
      setSelectedTask(null);
      setCurrentCategory(null);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await deleteTask(taskId); // Suppression de la tâche via l'API
      setTasks(tasks.filter((task) => task._id !== taskId)); // Mise à jour de l'état des tâches localement
      setShowConfirmationModal(false); // Fermeture du modal de confirmation
    } catch (error) {
      console.error("Erreur lors de la suppression de la tâche :", error);
    }
  };

  const openConfirmationModal = (task) => {
    setConfirmationModalData(task.title);
    setShowConfirmationModal(true); // Afficher le modal
  };

  const handleModalConfirm = async () => {
    if (selectedTask) {
      const updatedTask = {
        ...selectedTask,
        status: newStatus,
        notes: selectedTask.notes,
        members: selectedTask.members,
      };

      try {
        await updateTaskOnServer(updatedTask);
        handleTaskUpdate(updatedTask);
        setShowModal(false);
        setSelectedTask(null);
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la tâche :", error);
      }
    }
  };

  const handleTaskUpdate = (updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks
        .map((task) => (task._id === updatedTask._id ? updatedTask : task))
        .sort((a, b) => b.priority - a.priority),
    );
  };

  const openEditModal = (task) => {
    setSelectedTask(task);
    setNewStatus(task.status);
    setMembers(task.members);
    setAvailableMembers(
      predefinedMembers.filter((member) => !task.members.includes(member)),
    );
    setShowModal(true);
  };

  const addMember = (member) => {
    if (!selectedTask || members.includes(member)) return;

    const updatedMembers = [...members, member];
    setMembers(updatedMembers);
    setAvailableMembers(availableMembers.filter((m) => m !== member));
    setSelectedTask({ ...selectedTask, members: updatedMembers });
  };

  const removeMember = (member) => {
    if (!selectedTask) return;

    const updatedMembers = members.filter((m) => m !== member);
    setMembers(updatedMembers);
    setAvailableMembers([...availableMembers, member]);
    setSelectedTask({ ...selectedTask, members: updatedMembers });
  };

  const addNote = () => {
    if (!selectedTask || !newNote) return;

    const newNoteObject = {
      date: new Date().toISOString(),
      by: localStorage.getItem("USERNAME") || "current user",
      message: newNote,
    };

    const updatedNotes = [...selectedTask.notes, newNoteObject];
    setSelectedTask({ ...selectedTask, notes: updatedNotes });
    setNewNote("");
  };

  const removeNote = (index) => {
    if (!selectedTask) return;

    const updatedNotes = selectedTask.notes.filter((_, i) => i !== index);
    setSelectedTask({ ...selectedTask, notes: updatedNotes });
  };

  const categories = [
    { title: "Tâches", color: "border-red-500", type: null, status: 0 },
    { title: "Autre", color: "border-pink-900", type: "Autre", status: 1 },
    { title: "Builder", color: "border-blue-500", type: "Builder", status: 1 },
    { title: "Dev", color: "border-yellow-500", type: "Dev", status: 1 },
    { title: "Tech", color: "border-orange-500", type: "Tech", status: 1 },
    { title: "Terminée", color: "border-green-500", type: null, status: 100 },
  ];

  return (
    <section className="flex items-start justify-between flex-1 flex-wrap min-h-[70vh]">
      {categories.map((category, index) => (
        <section
          key={index}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e, category)}
          className="flex flex-col gap-5 max-h-[95%] "
        >
          <h2 className="font-bold uppercase text-lg">{category.title}</h2>
          <section
            className={clsx(
              "bg-gray-100 w-[14rem] p-2 border-4 rounded overflow-scroll min-h-56 max-h-96 lg:min-h-[40rem]",
              category.color,
            )}
          >
            {tasks
              .filter((task) => {
                if (category.status === 100) {
                  return task.status === 100;
                } else if (category.status === 0) {
                  return (
                    task.status === 0 || (task.status > 0 && task.type === null)
                  );
                } else {
                  return (
                    task.status > 0 &&
                    task.status < 100 &&
                    task.type === category.type
                  );
                }
              })
              .map((task) => (
                <div
                  key={task._id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, task._id)}
                  className={clsx(
                    "p-2 mb-2 bg-white border border-2 rounded shadow relative w-full",
                    getBorderColor(task.type),
                    "border-l-8",
                  )}
                >
                  <div className=" flex gap-2 items-end justify-end select-none">
                    <p
                      className=" cursor-pointer text-blue-600 underline select-none"
                      onClick={() => openEditModal(task)}
                    >
                      <MdEditDocument />
                    </p>
                    <p
                      className=" cursor-pointer text-red-600 underline"
                      onClick={() => openConfirmationModal(task)}
                    >
                      <FaRegTrashCan />
                    </p>
                  </div>
                  <h3 className="font-bold text-lg">{task.title}</h3>
                  <p className="text-sm">{task.description}</p>
                  <div className="py-2 flex gap-2 flex-wrap select-none">
                    {task.members.map((el, index) => (
                      <img
                        key={index}
                        className="w-5 h-5 rounded shadow-2xl select-none cursor-pointer"
                        src={`https://mc-heads.net/avatar/${getUsername[el]}`}
                        alt={`user : ${el}`}
                      />
                    ))}
                  </div>
                  <p className="text-xs text-gray-500">
                    Créée le {showDate(task.createdAt)}
                  </p>
                  <div className="flex items-center justify-between">
                    <p
                      className={` flex items-center text-xs mt-1 ${getPriorityColor(task.priority)}`}
                    >
                      <GoDotFill size="20" /> {getPriorityLabel(task.priority)}
                    </p>
                    <p className={`text-xs mt-1 `}>
                      {formatStatusText(task.status)}{" "}
                    </p>
                  </div>
                </div>
              ))}
          </section>
        </section>
      ))}

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90">
          <div className="bg-white p-6 rounded shadow-lg w-[80%] max-w-4xl">
            <h2 className="text-lg font-bold mb-4">Modifier la tâche</h2>
            <div className="grid grid-cols-2 gap-6">
              {/* Colonne 1 */}
              <div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Titre</label>
                  <input
                    type="text"
                    value={selectedTask?.title || ""}
                    onChange={(e) =>
                      setSelectedTask({
                        ...selectedTask,
                        title: e.target.value,
                      })
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Description
                  </label>
                  <textarea
                    value={selectedTask?.description || ""}
                    onChange={(e) =>
                      setSelectedTask({
                        ...selectedTask,
                        description: e.target.value,
                      })
                    }
                    className="w-full p-2 border border-gray-300 rounded resize-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Priorité</label>
                  <select
                    value={selectedTask?.priority || 1}
                    onChange={(e) =>
                      setSelectedTask({
                        ...selectedTask,
                        priority: parseInt(e.target.value, 10),
                      })
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value={5} className="bg-red-500 text-white">
                      Urgent
                    </option>
                    <option value={4} className="bg-orange-500 text-white">
                      Important
                    </option>
                    <option value={3} className="bg-yellow-500 text-white">
                      Moyen
                    </option>
                    <option value={2} className="bg-blue-500 text-white">
                      Faible
                    </option>
                    <option value={1} className="bg-gray-500 text-white">
                      Très faible
                    </option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Status</label>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={selectedTask?.status || 0}
                    onChange={handleStatusChange}
                    className="w-full"
                  />
                  <p className="text-xs text-gray-500">
                    {formatStatusText(selectedTask?.status)}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Members</label>
                  <div className="mb-2">
                    <label className="block text-sm font-medium">
                      Ajouter un membre
                    </label>
                    <select
                      onChange={(e) => addMember(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Sélectionner un membre</option>
                      {availableMembers.map((member) => (
                        <option key={member} value={member}>
                          {member}
                        </option>
                      ))}
                    </select>
                  </div>
                  <ul className="list-disc ml-5">
                    {members.map((member) => (
                      <li
                        key={member}
                        className="flex justify-between items-center"
                      >
                        {member}
                        <button
                          onClick={() => removeMember(member)}
                          className="ml-2 text-red-500"
                        >
                          Supprimer
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Colonne 2 */}
              <div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Notes</label>
                  <div className="mb-2">
                    <textarea
                      value={newNote}
                      onChange={(e) => setNewNote(e.target.value)}
                      placeholder="Ajouter une note"
                      className="w-full p-2 border border-gray-300 rounded resize-none"
                    />
                    <button
                      onClick={addNote}
                      className="mt-2 bg-blue-500 text-white p-2 rounded"
                    >
                      Ajouter Note
                    </button>
                  </div>
                  <div className="max-h-[300px]  overflow-x-scroll">
                    {selectedTask?.notes.map((note, index) => (
                      <div
                        key={index}
                        className="mb-2 p-2 border border-gray-300 rounded"
                      >
                        <p className="text-xs text-gray-500">
                          {showDate(new Date(note.date))} - {note.by}
                        </p>
                        <p>{note.message}</p>
                        <button
                          onClick={() => removeNote(index)}
                          className="mt-1 text-red-500 text-xs"
                        >
                          Supprimer
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-4 items-center justify-between mt-6">
              <button
                onClick={handleModalConfirm}
                className="bg-green-500 text-white p-2 rounded font-semibold"
              >
                Confirmer
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-red-500 text-white p-2 rounded font-semibold"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Progress Modal */}
      {showProgressModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-bold">Changer le statut</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium">Status</label>
              <input
                type="range"
                min="0"
                max="99"
                step="1"
                value={newStatus}
                onChange={handleStatusChange}
                className="w-full"
              />
              <p className="text-xs text-gray-500">
                {formatStatusText(newStatus)}
              </p>
            </div>
            <div className="flex gap-4">
              <button
                onClick={handleProgressModalConfirm}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Confirmer
              </button>
              <button
                onClick={() => setShowProgressModal(false)}
                className="bg-gray-500 text-white p-2 rounded"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          name={confirmationModalData}
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={() => handleDeleteTask(confirmationModalData._id)}
        />
      )}
    </section>
  );
};

export default TaskManager;
