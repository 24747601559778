import { createSlice } from "@reduxjs/toolkit";

export const routingSlice = createSlice({
  name: "route",
  initialState: {
    FetchingRoute: [],
  },
  reducers: {
    setRoute: (state, action) => {
      return { ...state, FetchingRoute: action.payload };
    },
    getRoute: (state) => {
      return { ...state.FetchingRoute };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRoute, getRoute } = routingSlice.actions;

export default routingSlice.reducer;
