import React from "react";
import PropTypes from "prop-types";
import { Button, TextField, Switch, Label } from "react-aria-components";

const ActionButtonBlock = ({
  content,
  onChange,
  settings,
  onSettingsChange,
}) => {
  const handleContentChange = (e) => {
    onChange(e.target.value);
  };

  const handleRedirectUrlChange = (e) => {
    onSettingsChange({ ...settings, redirectUrl: e.target.value });
  };

  const handleOpenInNewTabChange = (checked) => {
    onSettingsChange({ ...settings, openInNewTab: checked });
  };

  const handleStyleChange = (style) => {
    onSettingsChange({ ...settings, style });
  };

  const handleRedirect = () => {
    const { redirectUrl, openInNewTab } = settings;
    if (redirectUrl) {
      if (openInNewTab) {
        window.open(redirectUrl, "_blank");
      } else {
        window.location.href = redirectUrl;
      }
    } else {
      alert("Veuillez entrer une URL de redirection valide.");
    }
  };

  // Définition des classes pour chaque style de bouton
  const getButtonClass = (style) => {
    const baseClasses = "px-4 py-2 rounded transition w-full text-black";
    const styleClasses = {
      primary:
        "bg-white text-black border border-black rounded-full hover:opacity-90",
      secondary: "bg-blue-600 text-white hover:opacity-90",
      danger: "bg-red-600 hover:opacity-90",
      exampleStyle:
        "font-exo text-white font-[800] uppercase outline:none hover:bg-gradient-hover transition-bg duration-500 bg-custom-gradient text-white text-lg center p-1 rounded-md shadowCustom",
    };
    return `${baseClasses} ${styleClasses[style] || styleClasses.primary}`;
  };

  return (
    <div className="space-y-4">
      <TextField className="w-full">
        <Label className="block text-sm font-medium text-gray-700 mb-1">
          Texte du bouton
        </Label>
        <input
          className="w-full p-2 border rounded focus:border-blue-500 outline-none"
          value={content}
          onChange={handleContentChange}
        />
      </TextField>

      <TextField className="w-full">
        <Label className="block text-sm font-medium text-gray-700 mb-1">
          URL de redirection
        </Label>
        <input
          className="w-full p-2 border rounded focus:border-blue-500 outline-none"
          value={settings.redirectUrl || ""}
          onChange={handleRedirectUrlChange}
          type="url"
        />
      </TextField>

      <Switch
        className="flex items-center space-x-2 mb-4"
        isSelected={settings.openInNewTab || false}
        onChange={handleOpenInNewTabChange}
      >
        <div
          className={`w-10 h-6 rounded-full ${settings.openInNewTab ? "bg-blue-600" : "bg-gray-200"} transition`}
        >
          <div
            className={`w-4 h-4 rounded-full bg-white transform transition ${settings.openInNewTab ? "translate-x-5" : "translate-x-1"}`}
          />
        </div>
        <Label className="text-sm text-gray-700">
          Ouvrir dans un nouvel onglet
        </Label>
      </Switch>

      {/* Boutons pour appliquer les styles */}
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => handleStyleChange("primary")}
          className={getButtonClass("primary")}
        >
          Primaire
        </button>
        <button
          onClick={() => handleStyleChange("secondary")}
          className={getButtonClass("secondary")}
        >
          Secondaire
        </button>
        <button
          onClick={() => handleStyleChange("danger")}
          className={getButtonClass("danger")}
        >
          Danger
        </button>
        <button
          onClick={() => handleStyleChange("exampleStyle")}
          className={getButtonClass("exampleStyle")}
        >
          autre
        </button>
      </div>

      <Button
        className={getButtonClass(settings.style)}
        onPress={handleRedirect}
      >
        {content || "Bouton de redirection"}
      </Button>
    </div>
  );
};

ActionButtonBlock.propTypes = {
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    redirectUrl: PropTypes.string,
    openInNewTab: PropTypes.bool,
    style: PropTypes.oneOf(["primary", "secondary", "danger", "exampleStyle"]),
  }).isRequired,
  onSettingsChange: PropTypes.func.isRequired,
};

export default ActionButtonBlock;
