import React, { useState } from "react";
import MockedData from "../../articleData/data";
import { clsx } from "clsx";
import ArticleLightbox from "./ArticleLightBox";
import {
  getTimeSinceDate,
  getYouTubeEmbedUrl,
  showDate,
} from "../../../../utils/utils";

const ArticlePreview = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [useMockedData, setUseMockedData] = useState(false); // Variable pour choisir la source des données

  const data = useMockedData ? MockedData[0] : props; // Choix des données

  const [article, setArticle] = useState([...data.data]);

  // Recueillir toutes les images dans un tableau plat
  const images = article
    .filter(
      (block) =>
        block.type === "IMAGE" ||
        block.type === "IMAGE_BLOCK" ||
        block.type === "BANNER",
    )
    .flatMap((block) => {
      return block.type === "IMAGE" ? [block.content] : block.content;
    });
  console.log(article);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (images, index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => setLightboxOpen(false);
  const nextImage = () =>
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  const previousImage = () =>
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);

  const handleButtonClick = (url, openInNewTab) => {
    if (url) {
      if (openInNewTab) {
        window.open(url, "_blank");
      } else {
        window.location.href = url;
      }
    } else {
      alert("URL de redirection non spécifiée.");
    }
  };

  return (
    <div className="bg-NO_DarkGray text-white min-h-screen w-full overflow-y-auto h-[calc(100vh-30vh)] py-10">
      <header className="p-5 ">
        <h1 className="p-2 text-6xl font-black bg-custom-gradient bg-clip-text text-transparent inline-block mb-2 uppercase">
          {data.title}
        </h1>
        <div className="flex justify-between items-center text-sm mt-6">
          <span className="pl-5 mr-4 uppercase font-exo font-semibold">
            Le {showDate(data.date)} - {getTimeSinceDate(data.date)}
          </span>
          <span className="font-black">
            Id écrit par{" "}
            <span className="uppercase text-yellow-500">{data.author}</span>
          </span>
        </div>
      </header>
      <section className="mb-7">
        <div className="max-h-[40vh] w-full">
          <img
            className="w-full max-h-[40vh] object-cover pos-top"
            src={data.banner}
            alt={data.title}
          />
        </div>
      </section>
      <main className="max-w-4xl mx-auto p-6">
        {article.map((block, index) => {
          switch (block.type) {
            case "BANNER":
              return (
                <div key={index} className="mb-6 py-5">
                  <img
                    src={block.content}
                    alt="Banner"
                    className="w-full h-48 object-cover rounded"
                  />
                </div>
              );
            case "TITLE":
              const getTitleClass = () => {
                if (!block.settings) return;

                const fontSizeClass = block.settings.fontSize
                  ? `text-${block.settings.fontSize}xl`
                  : "text-xl";

                const styleClass = (block.settings.style || [])
                  .map(
                    (style) =>
                      ({
                        bold: "font-bold",
                        italic: "italic",
                        underline: "underline",
                      })[style],
                  )
                  .join(" ");

                const positionClass =
                  {
                    left: "text-left",
                    center: "text-center",
                    right: "text-right",
                  }[block.settings.position] || "text-left";

                const caseClass =
                  {
                    uppercase: "uppercase",
                    lowercase: "lowercase",
                    capitalize: "capitalize",
                  }[block.settings.textCase] || "";

                const gradientClass = block.settings.gradient
                  ? "bg-custom-gradient bg-clip-text text-transparent inline-block"
                  : "";

                return `${fontSizeClass} ${styleClass} ${positionClass} ${caseClass} ${gradientClass}`;
              };

              return (
                <h2 key={index} className={clsx(getTitleClass(), "py-5")}>
                  {block.content}
                </h2>
              );

            case "PARAGRAPH":
              return (
                <p
                  key={index}
                  className="mb-4 py-5"
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
              );

            case "VIDEO":
              return (
                <iframe
                  width="100%"
                  height="512"
                  src={getYouTubeEmbedUrl(block.content)}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="mt-4 rounded shadow"
                />
              );
            case "IMAGE":
              return (
                <div key={index} className="mb-6 py-5 h-96 rounded-lg">
                  <img
                    src={block.content}
                    alt="Id image"
                    className="w-full h-full rounded cursor-zoom-in"
                    onClick={() =>
                      openLightbox(images, images.indexOf(block.content))
                    }
                  />
                </div>
              );
            case "IMAGE_BLOCK":
              return (
                <div
                  key={index}
                  className="flex flex-wrap justify-center gap-2 mb-6 py-5"
                >
                  {block.content.slice(0, 4).map((image, i) => (
                    <div
                      key={i}
                      className={`relative ${i === 0 ? "rounded-tl-lg" : ""} ${i === 1 ? "rounded-tr-lg" : ""} ${i === 2 ? "rounded-bl-lg" : ""} ${i === 3 ? "rounded-br-lg" : ""} flex-1 ${i < 2 ? "h-64" : "h-64"} overflow-hidden`}
                      style={{ flexBasis: "calc(50% - 1rem)" }} // Adjust width for the gap
                    >
                      <img
                        src={image}
                        alt={`Gallery image ${i + 1}`}
                        className={`w-full h-full cursor-zoom-in object-cover ${i === 0 ? "rounded-tl-lg" : ""} ${i === 1 ? "rounded-tr-lg" : ""} ${i === 2 ? "rounded-bl-lg" : ""} ${i === 3 ? "rounded-br-lg" : ""}`}
                        onClick={() =>
                          openLightbox(images, images.indexOf(image))
                        }
                      />
                      {i === 3 && block.content.length > 4 && (
                        <div className="absolute bottom-0 right-0">
                          <button
                            className="bg-[#000000cc] text-white font-bold px-4 py-2 rounded-tl-lg rounded-br-md"
                            onClick={() =>
                              openLightbox(images, images.indexOf(image))
                            }
                          >
                            + {block.content.length - 4}
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              );

            case "ACTION_BUTTON":
              const getButtonClass = (style) => {
                const baseClasses =
                  "px-7 py-2 rounded text-black transition duration-500";
                const styleClasses = {
                  primary:
                    "bg-white border border-black rounded-full hover:opacity-90",
                  secondary: "bg-blue-600 text-white hover:opacity-90",
                  danger: "bg-red-600 hover:opacity-90",
                  exampleStyle:
                    "font-exo text-white font-[800] uppercase outline:none hover:bg-gradient-hover transition-bg duration-500 bg-custom-gradient text-white text-lg center p-1 rounded-md shadowCustom",
                };
                return `${baseClasses} ${styleClasses[style] || styleClasses.primary}`;
              };

              return (
                <div
                  key={index}
                  className="my-6 flex flex-col items-center text-center py-5"
                >
                  <button
                    onClick={() =>
                      handleButtonClick(
                        block.settings.redirectUrl,
                        block.settings.openInNewTab,
                      )
                    }
                    className={getButtonClass(block.settings.style)}
                  >
                    {block.content}
                  </button>
                </div>
              );
            default:
              return null;
          }
        })}
        <hr />
        <section className="flex items-center justify-center flex-col gap-5 my-8">
          <h2 className="text-xl font-bold uppercase">preview de la carte :</h2>
          <div
            className="relative w-[400px] h-[400px] rounded-lg overflow-hidden cursor-pointer"
            style={{
              background: "linear-gradient(0deg, #f2a206 9.9%, #d75151 70.4%)",
              padding: "5px",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="w-full h-full relative overflow-hidden rounded-lg">
              <img
                src={data.banner}
                alt="Minecraft spawn area"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0">
                <h2
                  className="absolute top-2 left-2 text-white font-bold text-xl uppercase "
                  style={{
                    textShadow: "2px 2px 2px  black",
                    padding: "5px",
                  }}
                >
                  {data.title}
                </h2>
                <div
                  className={`absolute left-0 bottom-0 w-full transition-transform duration-300 ease-in-out py-4 px-3 transform  bg-black bg-opacity-80 ${
                    isHovered ? "translate-y-0" : "translate-y-full"
                  }`}
                  style={{
                    minHeight: "80px", // Ajustez cette valeur selon la taille de votre texte
                  }}
                >
                  <p className="text-white text-sm mb-3">{data.description}</p>
                  <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 px-2 py-1 rounded">
                    <span className="text-white text-xs font-bold">
                      {getTimeSinceDate(data.date)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {lightboxOpen && (
        <ArticleLightbox
          images={images}
          currentIndex={currentImageIndex}
          onClose={closeLightbox}
          onNext={nextImage}
          onPrevious={previousImage}
        />
      )}
    </div>
  );
};

export default ArticlePreview;
