import React, { useEffect, useState } from "react";
import { getAllGallery } from "../../../../services/services";

const ArticleMedia = ({ onSelect }) => {
  const [media, setMedia] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { response, data } = await getAllGallery();
      if (response.status === 200) {
        setMedia(data);
      } else {
        console.error("Erreur lors du chargement des images:", response);
      }
    };
    fetchData();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleOkClick = () => {
    if (selectedImage) {
      onSelect(selectedImage.image);
    }
  };

  return (
    <div>
      {selectedImage && (
        <div className="mt-4 mb-2 w-full m-auto">
          <button
            onClick={handleOkClick}
            className="px-4 py-2 bg-blue-500 text-white rounded w-full "
          >
            OK
          </button>
        </div>
      )}
      <div className="w-[90%] m-auto max-h-[90vh] overflow-y-auto grid grid-cols-7 gap-2 ">
        {media.map((item) => (
          <div
            key={item._id}
            className={`cursor-pointer p-2 border ${
              selectedImage && selectedImage._id === item._id
                ? "border-blue-500"
                : "border-transparent"
            }`}
            onClick={() => handleImageClick(item)}
          >
            <img
              src={item.image}
              alt={item.name}
              className="object-cover w-full h-32 rounded"
            />
            <p className="mt-2 text-center text-white">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleMedia;
