import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  SearchField,
  Switch,
} from "react-aria-components";
import { useFilter } from "react-aria";

import Table from "./MinecraftUserTable";
import { getAccounts } from "../../../../services/services";

const MineCraftUser = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeOptions = [25, 50, 75];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, response } = await getAccounts();

        if (response.status === 200) {
          setData(data);
        }
      } catch (error) {
        if (error.response) return;
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  let { contains } = useFilter({
    sensitivity: "base",
  });

  let matchedComposers = data.filter((composer) => {
    const matchesSearch =
      value.length > 3
        ? contains(
            composer.USERNAME.toLowerCase().trim(),
            value.toLowerCase(),
          ) ||
          contains(composer.GROUP.toLowerCase().trim(), value.toLowerCase()) ||
          contains(composer.LANG.toLowerCase().trim(), value.toLowerCase()) ||
          contains(
            composer.UUID.toLowerCase().trim(),
            value.toLowerCase().trim(),
          ) ||
          contains(
            composer.HOST_IP.toLowerCase().trim(),
            value.toLowerCase().trim(),
          ) ||
          contains(
            composer.LANG.toLowerCase().trim(),
            value.toLowerCase().trim(),
          )
        : true;

    const matchesOnlineFilter =
      composer.ONLINE === (true || false) || composer.ONLINE === isOnline;

    return matchesSearch && matchesOnlineFilter;
  });

  const resetFilters = () => {
    setIsOnline(false);
  };

  const totalItems = matchedComposers.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    if (page === 0) return setCurrentPage(1);
    if (page > totalPages) return setCurrentPage(totalPages);
    setCurrentPage(page);
  };

  return (
    <section>
      <section className="bg-gray-100 w-full p-2 overflow-hidden">
        <div className="flex gap-3 items-center justify-between w-full">
          <SearchField className="flex items-center p-2">
            <Label className="mr-2 text-black font-semibold">
              Rechercher un utilisateur
            </Label>
            <Input
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setCurrentPage(1);
              }}
              className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Entrez le nom de l'utilisateur..."
            />
            <Button
              onPress={() => setValue("")}
              className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
              aria-label="Clear search"
            >
              ✕
            </Button>
            <div className="flex items-center gap-3 ml-3">
              <Switch
                isSelected={isOnline}
                onChange={setIsOnline}
                className="group inline-flex touch-none cursor-pointer"
              >
                <span className="sr-only">
                  Afficher seulement les joueurs connectés
                </span>
                <span className="bg-gray-400 group-data-[selected]:bg-green-600 relative inline-block h-6 w-11 rounded-full transition-colors">
                  <span
                    className={`absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition-transform ${
                      isOnline ? "translate-x-5" : ""
                    }`}
                  />
                </span>
              </Switch>
              <Label className="text-black font-semibold cursor-pointer">
                Afficher seulement les joueurs connectés
              </Label>
            </div>
          </SearchField>

          <div className="flex items-center gap-3 text-white">
            <Label className="font-semibold text-black text-right pl-2">
              Résultat:{" "}
            </Label>
            <p className="center text-black px-2 rounded-md border-[#8f8f8f] border p-2">
              {matchedComposers.length} / {data.length} Joueurs
            </p>
          </div>
        </div>

        <section className="shadowCustom w-full h-[75vh] mt-2 rounded max-h-[73vh] !overflow-scroll">
          <Table
            data={matchedComposers}
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
          />
        </section>
        <div className="flex justify-between mt-2">
          <div>
            <Label className="text-black">Éléments par page: </Label>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="text-black px-2 rounded-md border-[#8f8f8f] border"
            >
              {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 text-back">
              <p className="">{`Page `}</p>
              <Input
                className="bg-transparent w-8 text-center border border-black rounded outline-none"
                value={currentPage}
                onChange={(e) => {
                  handlePageChange(Number(e.target.value));
                }}
              />
              <p className="">{` / ${totalPages}`}</p>
            </div>

            <Button
              onPress={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="text-black px-2 rounded-md border-[#8f8f8f] border"
            >
              Précédent
            </Button>
            <Button
              onPress={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="text-black px-2 rounded-md border-[#8f8f8f] border"
            >
              Suivant
            </Button>
          </div>
        </div>
      </section>
    </section>
  );
};

export default MineCraftUser;
