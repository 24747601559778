import React, { useState } from "react";

const ConfirmationModal = ({ name, onClose, onConfirm }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDelete = async () => {
    if (inputValue === name) {
      onConfirm(); // Appel de la fonction pour confirmer la suppression
    } else {
      alert("Le nom de la tâche ne correspond pas.");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-semibold text-gray-800 mb-4 ">
          Confirmer la suppression
        </h2>
        <p className="text-red-600 mb-4">
          Pour supprimer la tâche{" "}
          <strong className="text-blue-700">{name}</strong>, <br />
          <span className="text-gray-600">
            {" "}
            veuillez entrer son nom ci-dessous :
          </span>
        </p>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Entrez le nom de la tâche"
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
        />
        <div className="flex justify-end">
          <button
            onClick={onClose} // Fermeture du modal
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 px-4 rounded-md mr-2"
          >
            Annuler
          </button>
          <button
            onClick={handleDelete}
            className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-md"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
