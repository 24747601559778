import React, { useState } from "react";
import {
  DialogTrigger,
  Button,
  Modal,
  Dialog,
  Heading,
} from "react-aria-components";
import { FaEye } from "react-icons/fa";
import ArticlePreview from "./ArticlePreview";

const RowOptionsMenuTriggerArticle = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(props.data);
  console.log("RowOptionsMenuTriggerArticle", modalData);
  return (
    <DialogTrigger>
      <Button
        className="flex items-center border border-gray-300 gap-5 rounded px-2 uppercase font-bold text-gray-700"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <FaEye /> Voir l'Article
      </Button>
      <Modal
        className="fixed top-0 left-0 right-0 bottom-0 z-[1000] bg-[#212121] p-2 rounded overflow-hidden"
        isOpen={isOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsOpen(false);
        }}
      >
        {modalData && (
          <Dialog className="w-full overflow-hidden">
            {({ close }) => (
              <div className="relative w-full h-[98vh] flex  ">
                <Heading
                  slot="title"
                  className="text-[25px] px-2 py-1 text-white font-bold uppercase"
                >
                  {/* Add title here if needed */}
                </Heading>

                <ArticlePreview
                  title={modalData.TITLE}
                  date={modalData.DATE}
                  description={modalData.DESCRIPTION}
                  author={modalData.AUTHOR}
                  banner={modalData.BANNER}
                  data={modalData.BLOCKS}
                />

                <Button
                  className="absolute top-5 shadow font-semibold right-5 px-3 py-1 text-white rounded bg-red-700 hover:bg-red-500 transition-bg duration-500"
                  onPress={close}
                >
                  Fermer
                </Button>
              </div>
            )}
          </Dialog>
        )}
      </Modal>
    </DialogTrigger>
  );
};

export default RowOptionsMenuTriggerArticle;
