import React from "react";
import {
  Cell,
  Collection,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  useTableOptions,
} from "react-aria-components";

import FRENCH from "./FRENCH.jpg";
import ENGLISH from "./ENGLISH.jpg";
import DEUTSCH from "./DEUTSCH.jpg";
import { customCompare, formatTime } from "../../../../utils/utils";

function MyTableHeader({ columns }) {
  let { allowsDragging } = useTableOptions();

  return (
    <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md sticky top-0 z-10">
      {allowsDragging && <Column />}
      <Collection items={columns}>
        {(column) => (
          <Column
            className="p-1 text-white"
            key={column.id}
            isRowHeader={column.isRowHeader}
          >
            {column.name}
          </Column>
        )}
      </Collection>
      <Column />
    </TableHeader>
  );
}

function MyRow({ id, columns, item }) {
  return (
    <Row
      id={id}
      item={item}
      className="border-[#3f3f46] border-2 text-black bg-white"
    >
      {columns.map((col, index) => {
        let cellValue = checkColID(col.id, item);

        return (
          <Cell key={index} className="h-[40px] p-2 text-center">
            {cellValue}
          </Cell>
        );
      })}
    </Row>
  );
}

const flagLang = {
  FRENCH: FRENCH,
  ENGLISH: ENGLISH,
  DEUTSCH: DEUTSCH,
};

function checkColID(col, item) {
  switch (col) {
    case "TEXTURE":
      return (
        <span className="center">
          <img
            className="w-10 h-10 object-cover rounded shadowCustom"
            src={`https://mc-heads.net/avatar/${item.UUID}`}
            alt={"User Skin"}
          />
        </span>
      );

    case "LANG":
      return (
        <span className="center">
          <img
            className="w-8 h-5 object-cover rounded shadow "
            src={flagLang[item.LANG]}
            alt={item.LANG}
          />
        </span>
      );

    case "TIME_PLAYED":
      return formatTime(item[col]);

    case "ONLINE":
      return item[col] ? (
        <div className="flex justify-center items-center">
          <div className="w-3 h-3 bg-green-500 rounded-full relative">
            <span className="flex w-3 h-3 bg-green-500 rounded-full absolute animate-ping"></span>
          </div>
        </div>
      ) : (
        <div className="w-3 h-3 bg-red-500 rounded-full mx-auto"></div>
      );

    default:
      return item[col];
  }
}

const MinecraftUserTable = (props) => {
  const columns = [
    { name: "SKIN", id: "TEXTURE" },
    { name: "NOM", id: "USERNAME", isRowHeader: true },
    { name: "GRADE", id: "GROUP" },
    { name: "IP", id: "HOST_IP" },
    { name: "LANG", id: "LANG" },
    { name: "ONLINE", id: "ONLINE" },
    { name: "TEMPS DE JEUX", id: "TIME_PLAYED" },
    { name: "UUID", id: "UUID" },
  ];

  let rows = [...props.data].sort(customCompare);

  return (
    <Table
      aria-label="Minecraft User Table"
      disabledBehavior="selection"
      className="w-full max-w-[100%] max-h-[73vh] rounded-md overflow-hidden"
    >
      <MyTableHeader columns={columns} />
      <TableBody
        className="text-center text-black max-h-[60vh] overflow-y-auto"
        renderEmptyState={() => (
          <div className="p-4 text-center text-gray-400">
            Aucun résultat trouvé.
          </div>
        )}
      >
        {rows
          .slice(
            props.currentPage * props.pageSize - props.pageSize,
            props.currentPage * props.pageSize,
          )
          .map((item, index) => (
            <MyRow key={index} id={index} columns={columns} item={item} />
          ))}
      </TableBody>
    </Table>
  );
};

export default MinecraftUserTable;
