import React from "react";
import { createGridLines } from "./mapUtils";
import { Polyline } from "react-leaflet";

const gridComponents = () => {
  return (
    <>
      {createGridLines(16, 1024).map((line, index) => (
        <Polyline
          key={`chunk-${index}`}
          positions={line}
          color="#323232"
          weight={0.5}
        />
      ))}
      {createGridLines(512, 1024).map((line, index) => (
        <Polyline
          key={`region-${index}`}
          positions={line}
          color="black"
          weight={0.9}
        />
      ))}
    </>
  );
};

export default gridComponents;
