import React from "react";
import Containers from "../../components/Containers";
import TaskManager from "./containers/taskManager";

import TaskForm from "./containers/addNewTask";

const Dashboard = () => {
  document.title = "Dashboard";
  const tabs = [
    {
      name: "gestion projet",
      content: <TaskManager />,
    },
    {
      name: "nouvelle tache",
      content: <TaskForm />,
    },
  ];
  return <Containers tabs={tabs} title="Dashboard" />;
};

export default Dashboard;
