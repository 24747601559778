import React from "react";
import { Outlet } from "react-router";
import Navigation from "./Navigation";

const ConnectedLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navigation />

      <main className="flex-1 p-8 bg-NO_DarkGray text-white font-exo">
        {<Outlet />}
      </main>
    </div>
  );
};
export default ConnectedLayout;
