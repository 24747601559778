// FRONTEND_ERRORS.js

class FRONTEND_ERRORS {
  /**
   * Codes d'erreur prédéfinis avec les messages associés.
   * @readonly
   * @static
   * @type {Object<string, { message: string, httpCode: number }>}
   */
  static CODES = {
    ACCESS_DENY: {
      message:
        "Accès refusé. Vous n'avez pas la permission d'accéder à cette ressource.",
      httpCode: 403,
    },
    ACCESS_DENY_OWNER_NOT_MATCH: {
      message:
        "Accès refusé. La ressource ne appartient pas à l'utilisateur authentifié.",
      httpCode: 403,
    },
    ACCESS_DENY_TOKEN_EXPIRED: {
      message: "Votre session a expiré. Veuillez vous reconnecter.",
      httpCode: 401,
    },
    NO_TOKEN_PROVIDED: {
      message:
        "Aucun jeton d'authentification fourni. Veuillez vous connecter.",
      httpCode: 401,
    },
    TOKEN_INVALID: {
      message: "Jeton d'authentification invalide. Veuillez vous reconnecter.",
      httpCode: 403,
    },
    PERMISSION_DENIED: {
      message: "Permission refusée. Vous n'avez pas les droits requis.",
      httpCode: 403,
    },
    USER_BANNED: {
      message: "Votre compte a été banni. Veuillez contacter le support.",
      httpCode: 403,
    },
    USER_DECONNECTED: {
      message: "Vous avez été déconnecté. Veuillez vous reconnecter.",
      httpCode: 401,
    },
    ACCOUNT_DISABLED: {
      message: "Votre compte est désactivé. Veuillez contacter le support.",
      httpCode: 403,
    },
    INCORRECT_PASSWORD: {
      message: "Mot de passe incorrect. Veuillez réessayer.",
      httpCode: 401,
    },
    ACCOUNT_LOCKED: {
      message:
        "Votre compte est verrouillé en raison de trop nombreuses tentatives de connexion échouées. Veuillez réessayer plus tard.",
      httpCode: 403,
    },
    PASSWORD_NOT_MATCH: {
      message: "Le mot de passe ne correspond pas. Veuillez réessayer.",
      httpCode: 401,
    },
    PASSWORD_NOT_CONFORMING: {
      message:
        "Le mot de passe ne respecte pas les critères de sécurité. Veuillez en choisir un autre.",
      httpCode: 400,
    },
    TOO_MANY_CONNECTION_ATTEMPTS: {
      message:
        "Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.",
      httpCode: 429,
    },
    EMAIL_ALREADY_REGISTERED: {
      message:
        "L'adresse e-mail est déjà enregistrée. Veuillez en utiliser une autre.",
      httpCode: 400,
    },
    USER_NOT_FOUND: {
      message:
        "Utilisateur non trouvé. Veuillez vérifier les informations saisies.",
      httpCode: 404,
    },
    NOT_FOUND: {
      message: "Cette page n'existe pas !",
      httpCode: 404,
    },
    INVALID_CREDENTIALS: {
      message: "Identifiants invalides. Veuillez réessayer.",
      httpCode: 401,
    },
    ACCOUNT_NOT_ACTIVATED: {
      message:
        "Votre compte n'est pas encore activé. Veuillez vérifier votre e-mail pour l'activation.",
      httpCode: 403,
    },
    UPDATE_INFO_ERROR: {
      message:
        "Erreur lors de la mise à jour des informations. Veuillez réessayer plus tard.",
      httpCode: 500,
    },
    DUPLICATION_ERROR: {
      message: "Duplication détectée. Veuillez vérifier les données soumises.",
      httpCode: 400,
    },
    CONTENT_NOT_FOUND: {
      message:
        "Contenu non trouvé. Veuillez vérifier l'URL ou le contenu recherché.",
      httpCode: 404,
    },
    LIMIT_FILE_SIZE: {
      message:
        "Le fichier dépasse la taille limite autorisée. Veuillez choisir un fichier plus petit.",
      httpCode: 413,
    },
    ERROR_COMPRESS: {
      message: "Erreur lors de la compression du fichier. Veuillez réessayer.",
      httpCode: 500,
    },
    INVALID_DATA: {
      message:
        "Les données fournies sont invalides. Veuillez vérifier et réessayer.",
      httpCode: 400,
    },
    INCORRECT_FORMAT: {
      message: "Format incorrect des données. Veuillez vérifier et réessayer.",
      httpCode: 400,
    },
    FILE_NOT_FOUND: {
      message:
        "Fichier non trouvé. Veuillez vérifier le chemin ou le nom du fichier.",
      httpCode: 404,
    },
    DATA_VALIDATION_ERROR: {
      message:
        "Erreur de validation des données. Veuillez vérifier les informations soumises.",
      httpCode: 422,
    },
    ERR_BAD_REQUEST: {
      message: "Requête invalide. Veuillez vérifier les données envoyées.",
      httpCode: 400,
    },
    SERVER_NOT_RESPONDING: {
      message: "Le serveur ne répond pas. Veuillez réessayer plus tard.",
      httpCode: 503,
    },
    TO_MANY_REQUESTS: {
      message: "Trop de requêtes. Veuillez réessayer plus tard.",
      httpCode: 429,
    },
    INFO_NOT_FOUND: {
      message: "Informations non trouvées. Veuillez vérifier et réessayer.",
      httpCode: 404,
    },
    REQUEST_TIMEOUT: {
      message: "Délai d'attente de la requête expiré. Veuillez réessayer.",
      httpCode: 408,
    },
    RESOURCE_NOT_FOUND: {
      message:
        "Ressource non trouvée. Veuillez vérifier l'URL ou la ressource demandée.",
      httpCode: 404,
    },
    UNAUTHORIZED_ACCESS: {
      message: "Accès non autorisé. Veuillez vous connecter.",
      httpCode: 401,
    },
    SERVICE_UNAVAILABLE: {
      message: "Service non disponible. Veuillez réessayer plus tard.",
      httpCode: 503,
    },
    DATABASE_ERROR: {
      message: "Erreur de base de données. Veuillez réessayer plus tard.",
      httpCode: 500,
    },
    DEPENDENCY_FAILURE: {
      message: "Échec de la dépendance. Veuillez réessayer plus tard.",
      httpCode: 500,
    },
    FEATURE_NOT_IMPLEMENTED: {
      message:
        "Fonctionnalité non implémentée. Veuillez contacter le support pour plus d'informations.",
      httpCode: 501,
    },
    ACTION_NOT_ALLOWED: {
      message: "Action non autorisée. Veuillez vérifier vos droits d'accès.",
      httpCode: 403,
    },
    RATE_LIMIT_EXCEEDED: {
      message: "Limite de taux dépassée. Veuillez réessayer plus tard.",
      httpCode: 429,
    },
    OPERATION_FAILED: {
      message: "L'opération a échoué. Veuillez réessayer plus tard.",
      httpCode: 500,
    },
    DATA_NOT_FOUND: {
      message:
        "Données non trouvées. Veuillez vérifier les informations soumises.",
      httpCode: 404,
    },
    CSRF_TOKEN_INVALID: {
      message: "Jeton CSRF invalide. Veuillez réessayer.",
      httpCode: 403,
    },
    XSS_ATTACK_DETECTED: {
      message:
        "Détection d'une attaque XSS. Veuillez signaler ce problème au support.",
      httpCode: 400,
    },
    SQL_INJECTION_DETECTED: {
      message:
        "Détection d'une injection SQL. Veuillez signaler ce problème au support.",
      httpCode: 400,
    },
    AUTHENTICATION_ERROR: {
      message: "Erreur d'authentification. Veuillez vous reconnecter.",
      httpCode: 401,
    },
    UNEXPECTED_ERROR: {
      message:
        "Une erreur inattendue est survenue. Veuillez réessayer plus tard.",
      httpCode: 500,
    },
    INTERNAL_ERROR: {
      message:
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.",
      httpCode: 500,
    },
  };

  /**
   * Obtenir les informations d'erreur par code.
   * @param {string} code - Le code d'erreur à récupérer.
   * @returns {Object} Les informations d'erreur contenant le message et le code HTTP.
   */
  static getErrorInfo(code) {
    return this.CODES[code] || this.CODES.NOT_FOUND;
  }
}

export default FRONTEND_ERRORS;
