import { Route, Routes } from "react-router";
import RouteGuard from "./guard/RouteGuard";
import { isAuthenticate } from "./services/authContext";
import LoginPage from "./Pages/Login/Login";
import Dashboard from "./Pages/dashBoard/Dashboard";
import ConnectedLayout from "./components/Layout/ConnectedLayout";
import ErrorPage from "./Pages/Error/Error";
import Users from "./Pages/User/Users";
import Minecraft from "./Pages/Minecraft/Minecraft";
import WebSite from "./Pages/WebSite/WebSite";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FRONTEND_ERRORS from "./utils/ErrorsCode";

const AllowedRouting = ({ children, pathname }) => {
  const navigate = useNavigate();

  const allowedRoutes = useSelector((state) => state.route.FetchingRoute).map(
    (el) => el.link,
  );
  const errorInfo = FRONTEND_ERRORS.getErrorInfo("ACCESS_DENY");
  if (allowedRoutes.includes(pathname)) {
    return children;
  } else {
    navigate("/error", {
      state: {
        code: "ACCESS_DENY",
        status: errorInfo.httpCode,
        message: errorInfo.message,
      },
    });
    return null;
  }
};
function App() {
  return (
    <Routes>
      {/*Accès connection*/}
      <Route path="/" element={<LoginPage />} />
      {/*Accès compte*/}
      <Route
        path="/dashboard"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route
          path="/dashboard"
          element={
            <AllowedRouting pathname="/dashboard">
              <Dashboard />
            </AllowedRouting>
          }
        />
      </Route>

      <Route
        path="/users"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route
          path="/users"
          element={
            <AllowedRouting pathname="/users">
              <Users />
            </AllowedRouting>
          }
        />
      </Route>

      <Route
        path="/web"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route
          path="/web"
          element={
            <AllowedRouting pathname="/web">
              <WebSite />
            </AllowedRouting>
          }
        />
      </Route>

      <Route
        path="/minecraft"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route
          path="/minecraft"
          element={
            <AllowedRouting pathname="/minecraft">
              <Minecraft />
            </AllowedRouting>
          }
        />
      </Route>

      <Route
        path="/admin"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route
          path="/admin"
          element={
            <AllowedRouting pathname="/admin">
              <Minecraft />
            </AllowedRouting>
          }
        />
      </Route>

      {/*Accès Error*/}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
