import React, { useEffect, useState } from "react";
import {
  MapContainer,
  ImageOverlay,
  Marker,
  Popup,
  useMapEvents,
  Tooltip,
} from "react-leaflet";
import { Button, Input, Label, SearchField } from "react-aria-components";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import map from "./map.png";
import ZoneComponent from "./ZoneComponent";
import { convertCoordinates, reverseConvertCoordinates } from "./mapUtils";
import { getWarpMarker, getUtilsMarker } from "../../../../services/services";
import GridComponents from "./GridComponents";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [23, 35],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const bounds = [
  [0, 0],
  [1024, 1024],
];
const center = [512, 512];

const MapComponent = () => {
  const [markers, setMarkers] = useState({ warp: [], utils: [] });
  const [activeZone, setActiveZone] = useState(null);
  const [zoneOpacity, setZoneOpacity] = useState({});
  const [cursorCoords, setCursorCoords] = useState({ x: 0, z: 0 });
  const [showGrid, setShowGrid] = useState(false);
  const [showWarpMarkers, setShowWarpMarkers] = useState(false);
  const [showUtilsMarkers, setShowUtilsMarkers] = useState(false);
  const [showAudioArea, setShowAudioArea] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [warpMarkers, utilsMarkers] = await Promise.all([
          getWarpMarker(),
          getUtilsMarker(),
        ]);
        setMarkers({
          warp: warpMarkers.data.data,
          utils: utilsMarkers.data.data,
        });
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    };
    fetchData();
  }, []);
  const MapEvents = () => {
    useMapEvents({
      mousemove: (e) => {
        const { lat, lng } = e.latlng;
        const { x, z } = reverseConvertCoordinates(lat, lng);
        setCursorCoords({ x: Math.round(x), z: Math.round(z) });
      },
    });
    return null;
  };

  const LocationCoordinates = () => (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "white",
        padding: "5px",
        borderRadius: "4px",
        zIndex: 1000, // Ensure it appears on top
      }}
    >
      X: {cursorCoords.x}, Z: {cursorCoords.z}
    </div>
  );
  return (
    <section className="w-full flex">
      <div className="flex flex-col justify-center ml-4 h-[80vh] w-[50%]">
        <h2 className="text-2xl font-bold center flex pb-5">
          Carte interactive Novus Odyssey
        </h2>
        <div className=" flex flex-col gap-5 w-96 mx-auto">
          <SearchField className="flex  flex-col items-center p-2">
            <Label className="mr-2 text-black font-semibold">
              Rechercher une zone
            </Label>
            <div>
              <Input
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Entrez le nom "
              />
              <Button
                onPress={() => setSearchQuery("")}
                className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
                aria-label="Clear search"
              >
                ✕
              </Button>
            </div>
          </SearchField>

          <Button
            onPress={() => setShowGrid(!showGrid)}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            {showGrid ? "Masquer la grille" : "Afficher la grille"}
          </Button>
          <Button
            onPress={() => setShowWarpMarkers(!showWarpMarkers)}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            {showWarpMarkers
              ? "Masquer les marqueurs Warp"
              : "Afficher les marqueurs Warp"}
          </Button>
          <Button
            onPress={() => setShowUtilsMarkers(!showUtilsMarkers)}
            className="px-4 py-2 bg-violet-500 text-white rounded"
          >
            {showUtilsMarkers
              ? "Masquer les marqueurs Utils"
              : "Afficher les marqueurs Utils"}
          </Button>

          <Button
            onPress={() => setShowAudioArea(!showAudioArea)}
            className="px-4 py-2 bg-yellow-500 text-white rounded"
          >
            {showAudioArea
              ? "Masquer les Zones d'audio"
              : "Afficher les Zones d'audio"}
          </Button>
        </div>
      </div>

      <MapContainer
        maxBounds={bounds}
        center={center}
        zoom={0}
        minZoom={0}
        maxZoom={6}
        className="h-[80vh] w-[50%] relative"
        crs={L.CRS.Simple}
      >
        <ImageOverlay url={map} bounds={bounds} />
        <MapEvents />

        {showWarpMarkers &&
          markers.warp.map((marker, index) => {
            const [x, z] = convertCoordinates(marker.x, marker.z);
            return (
              <Marker key={`warp-${index}`} position={[x, z]}>
                <Popup>{marker.text}</Popup>
                <Tooltip direction="bottom" offset={[-15, 20]} permanent>
                  {marker.text}{" "}
                </Tooltip>
              </Marker>
            );
          })}

        {showUtilsMarkers &&
          markers.utils.map((marker, index) => {
            const [x, z] = convertCoordinates(marker.x, marker.z);
            return (
              <Marker key={`utils-${index}`} position={[x, z]}>
                <Popup>{marker.text}</Popup>
                <Tooltip direction="bottom" offset={[-15, 20]} permanent>
                  {marker.text}
                </Tooltip>
              </Marker>
            );
          })}

        {showAudioArea && (
          <ZoneComponent
            setActiveZone={setActiveZone}
            setZoneOpacity={setZoneOpacity}
            searchQuery={searchQuery}
          />
        )}
        {showGrid && <GridComponents />}
        <LocationCoordinates />
      </MapContainer>
    </section>
  );
};

export default MapComponent;
