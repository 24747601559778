import React, { useState } from "react";

import ArticleMedia from "../ArticleMedia"; // Assurez-vous d'importer ImageUploadModal

const ImageBlock = ({ content, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState(content);

  const openModal = () => setShowModal(true);

  const handleImageSelect = (imageUrl) => {
    setPreview(imageUrl);
    onChange(imageUrl);
    setShowModal(false);
  };

  return (
    <div>
      <button
        onClick={openModal}
        className="w-full p-2 mb-2 border rounded focus:border-blue-500 outline-none"
      >
        {preview ? (
          <img
            src={preview}
            alt="Aperçu"
            className="max-w-full h-auto rounded shadow mb-2"
          />
        ) : (
          "Choisir une image"
        )}
      </button>

      {showModal && <ArticleMedia onSelect={handleImageSelect} />}
    </div>
  );
};

export default ImageBlock;
