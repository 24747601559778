import React from "react";
import {
  FaServer,
  FaUsers,
  FaUserCheck,
  FaExclamationTriangle,
  FaSync,
} from "react-icons/fa";

const MinecraftServerStatus = () => {
  const serverData = {
    status: "restarting",
    totalUniquePlayers: 13,
    onlinePlayers: 0,
    maxPlayers: 45,
    motd: "Bienvenue sur notre serveur Minecraft !",
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "on":
        return "bg-green-500";
      case "off":
        return "bg-red-500";
      case "maintenance":
        return "bg-yellow-500";
      case "restarting":
        return "bg-blue-500";
      default:
        return "bg-gray-500";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "on":
        return "En ligne";
      case "off":
        return "Hors ligne";
      case "maintenance":
        return "Maintenance";
      case "restarting":
        return "Redémarrage";
      default:
        return "Inconnu";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "online":
        return <FaServer className="mr-2" />;
      case "offline":
        return <FaExclamationTriangle className="mr-2" />;
      case "maintenance":
        return <FaExclamationTriangle className="mr-2" />;
      case "restarting":
        return <FaSync className="mr-2 animate-spin" />;
      default:
        return <FaServer className="mr-2" />;
    }
  };

  return (
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4 flex items-center">
        <FaServer className="mr-2" /> Statut du Serveur Minecraft
      </h2>

      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span>Statut:</span>
          <span
            className={`px-2 py-1 rounded flex items-center ${getStatusColor(serverData.status)}`}
          >
            {getStatusIcon(serverData.status)}
            {getStatusText(serverData.status)}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="flex items-center">
            <FaUsers className="mr-2" /> Joueurs uniques totaux:
          </span>
          <span>{serverData.totalUniquePlayers}</span>
        </div>

        <div className="flex items-center justify-between">
          <span className="flex items-center">
            <FaUserCheck className="mr-2" /> Joueurs en ligne:
          </span>
          <span>
            {serverData.onlinePlayers}/{serverData.maxPlayers}
          </span>
        </div>

        {serverData.status === "on" && (
          <div>
            <span className="font-semibold">MOTD:</span>
            <p className="mt-1 text-gray-300">{serverData.motd}</p>
          </div>
        )}

        {serverData.status !== "online" && (
          <div className="text-yellow-300">
            {serverData.status === "maintenance" &&
              "Le serveur est actuellement en maintenance. Veuillez patienter."}
            {serverData.status === "restarting" &&
              "Le serveur est en cours de redémarrage. Il sera bientôt de retour !"}
            {serverData.status === "offline" &&
              "Le serveur est actuellement hors ligne. Veuillez réessayer plus tard."}
          </div>
        )}
      </div>
    </div>
  );
};

export default MinecraftServerStatus;
