import React, { useEffect, useState } from "react";
import { getRoutingPermissionTabs } from "../services/services";

const Containers = ({ title = "", tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs.length > 0 ? 0 : null);
  const [fetchingTabs, setFetchingTabs] = useState([]);
  useEffect(() => {
    const fetchingTabs = async () => {
      const tab = tabs.map((el) => {
        return { name: el.name, permission: el.permission };
      });

      const { data } = await getRoutingPermissionTabs(tab);
      if (data.statusCode === 200) {
        setFetchingTabs([...data.data.tabs]);
      }
    };
    fetchingTabs();
  }, []);

  const borderColors = [
    "border-red-500",
    "border-blue-500",
    "border-green-500",
    "border-yellow-500",
    "border-purple-500",
    "border-pink-500",
    "border-orange-500",
    "border-teal-500",
  ];
  const getTabsWithContent = () => {
    return tabs.filter((tab) =>
      fetchingTabs.some((fetchedTab) => fetchedTab.name === tab.name),
    );
  };
  return (
    <section id="CONTAINER" className="flex flex-col h-full">
      <div className="flex justify-between items-center">
        <div className="gap-2 flex items-end">
          {fetchingTabs.map((tab, index) => (
            <button
              key={index}
              className={`w-auto py-2 px-4 text-sm bg-white text-black uppercase font-light rounded-t-lg border-t-4 ${
                borderColors[index % borderColors.length]
              } ${activeTab === index ? "font-semibold h-14 w-28" : "h-10"}`}
              onClick={() => setActiveTab(index)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <h1 className="text-2xl font-bold uppercase ">{title}</h1>
      </div>

      <div className="flex-1 bg-white p-2 ">
        {getTabsWithContent().map((tab, index) =>
          activeTab === index ? (
            <div key={index} className="text-black h-full">
              {tab.content}
            </div>
          ) : null,
        )}
      </div>
    </section>
  );
};

export default Containers;
