import React from "react";

import Containers from "../../components/Containers";
import MinecraftServerStatus from "./container/MinecraftData";
import MinecraftMap from "./container/map/MinecraftMap";

const Minecraft = () => {
  const tabs = [
    {
      name: "dashboard",
      content: <MinecraftServerStatus />,
    },
    {
      name: "map",
      content: <MinecraftMap />,
    },
    {
      name: "panneau information",
      content: <h2>information</h2>,
    },
  ];
  return <Containers tabs={tabs} title="Minecraft" />;
};

export default Minecraft;
