import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FiTrash2 } from "react-icons/fi";
import clsx from "clsx";
import Notiflix from "notiflix";
import { postTask } from "../../../services/services";

// Validation schema
const TaskSchema = Yup.object().shape({
  PRIORITY: Yup.number().required("Priority is required").min(1).max(5),
  TITLE: Yup.string().required("Title is required"),
  STATUS: Yup.number().required("Status is required"),
  DESCRIPTION: Yup.string().required("Description is required"),
  NOTES: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.date().required("Date is required"),
        by: Yup.string().required("By is required"),
        message: Yup.string().required("Message is required"),
      }),
    )
    .required("At least one note is required"),
});

// Predefined members
const predefinedMembers = [
  "Thomas",
  "Lison",
  "Axel",
  "Marodeur",
  "Cancri",
  "Delta",
  "NoCheat",
];

const TaskForm = () => {
  const [members, setMembers] = useState([]);
  const [memberInput, setMemberInput] = useState("");
  const [availableMembers, setAvailableMembers] = useState(predefinedMembers);
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState({ message: "" });

  // Get username from localStorage
  const username = localStorage.getItem("USERNAME") || "Unknown";

  // Add a member to the list
  const addMember = (member) => {
    if (member && !members.includes(member)) {
      setMembers([...members, member]);
      setAvailableMembers(availableMembers.filter((m) => m !== member));
      setMemberInput("");
    }
  };

  // Remove a member from the list
  const removeMember = (member) => {
    setMembers(members.filter((m) => m !== member));
    setAvailableMembers([...availableMembers, member]);
  };

  // Add a note
  const addNote = () => {
    if (note.message) {
      setNotes([
        ...notes,
        { date: new Date().toISOString(), by: username, message: note.message },
      ]);
      setNote({ message: "" });
    }
  };

  // Remove a note
  const removeNote = (index) => {
    setNotes(notes.filter((_, i) => i !== index));
  };

  // Handle note field changes
  const handleNoteChange = (e) => {
    setNote({
      ...note,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    values.MEMBERS = members;
    values.NOTES = notes;

    try {
      await postTask(values);

      Notiflix.Notify.success("tâches crée!");
      resetForm();
      setMembers([]);
      setNotes([]);
      setAvailableMembers(predefinedMembers);
    } catch (error) {
      console.error("Error submitting task:", error);
      alert("There was an error submitting the task.");
    }
  };

  return (
    <div className="flex flex-col p-6 bg-gray-50 min-h-[70vh]">
      <Formik
        initialValues={{
          PRIORITY: 1,
          TITLE: "",
          STATUS: 0,
          DESCRIPTION: "",
          NOTES: [],
          TYPE: null,
        }}
        validationSchema={TaskSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form className="flex flex-col h-full bg-white rounded shadow-lg p-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full">
              {/* Left Column */}
              <div className="flex flex-col space-y-4">
                {/* Title */}
                <div>
                  <label
                    htmlFor="TITLE"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Titre
                  </label>
                  <Field
                    name="TITLE"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  />
                  <ErrorMessage
                    name="TITLE"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Priority */}
                <div>
                  <label
                    htmlFor="PRIORITY"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Priorité
                  </label>
                  <Field
                    as="select"
                    name="PRIORITY"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  >
                    <option value="">selectionné priorité</option>
                    <option value="5">Urgent</option>
                    <option value="4">Important</option>
                    <option value="3">Moyen</option>
                    <option value="2">Faible</option>
                    <option value="1">Très faible</option>
                  </Field>
                  <ErrorMessage
                    name="PRIORITY"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label
                    htmlFor="PRIORITY"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Type
                  </label>
                  <Field
                    as="select"
                    name="TYPE"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  >
                    <option value="">Selectionnée Type</option>
                    <option value="Builder">Builder</option>
                    <option value="Dev">Dev</option>
                    <option value="Tech">Tech</option>
                    <option value="Autre">Autre</option>
                  </Field>
                  <ErrorMessage
                    name="TYPE"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Status */}
                <div>
                  <label
                    htmlFor="STATUS"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Status
                  </label>
                  <input
                    type="range"
                    min="0"
                    max="99"
                    value={values.STATUS}
                    onChange={(e) => setFieldValue("STATUS", e.target.value)}
                    className="mt-1 w-full"
                  />
                  <span className="text-sm text-gray-500">
                    {values.STATUS}%
                  </span>
                  <ErrorMessage
                    name="STATUS"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Members */}
                <div>
                  <label
                    htmlFor="MEMBERS"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Membres
                  </label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex space-x-2">
                      <select
                        value={memberInput}
                        onChange={(e) => setMemberInput(e.target.value)}
                        className="mt-1 p-2 border border-gray-300 rounded-md flex-grow"
                      >
                        <option value="">Selectionné membre</option>
                        {availableMembers.map((member, index) => (
                          <option key={index} value={member}>
                            {member}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        onClick={() => addMember(memberInput)}
                        className="mt-1 px-3 py-2 bg-blue-600 text-white rounded-md"
                      >
                        Ajoutée
                      </button>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {members.map((member, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 justify-between bg-gray-100 p-2 rounded"
                        >
                          <span>{member}</span>
                          <button
                            type="button"
                            onClick={() => removeMember(member)}
                            className="text-red-600"
                          >
                            <FiTrash2 />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="flex flex-col space-y-4">
                {/* Description */}
                <div>
                  <label
                    htmlFor="DESCRIPTION"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <Field
                    as="textarea"
                    name="DESCRIPTION"
                    rows="4"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  />
                  <ErrorMessage
                    name="DESCRIPTION"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Notes */}
                <div>
                  <label
                    htmlFor="notes-message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Note
                  </label>
                  <textarea
                    name="message"
                    value={note.message}
                    onChange={handleNoteChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    rows="4"
                  />

                  <button
                    type="button"
                    onClick={addNote}
                    className="mt-2 px-3 py-2 bg-blue-600 text-white rounded-md"
                  >
                    Ajoutée Note
                  </button>

                  <div className="mt-4 max-h-[120px] overflow-y-scroll">
                    {notes.map((note, index) => (
                      <div
                        key={index}
                        className="mb-2 flex items-center justify-between bg-gray-100 p-2 rounded"
                      >
                        <div>
                          <p className="font-bold">{note.by}</p>
                          <p className="text-gray-600">
                            {new Date(note.date).toLocaleString()}
                          </p>
                          <p>{note.message}</p>
                        </div>
                        <button
                          type="button"
                          onClick={() => removeNote(index)}
                          className="text-red-600"
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-4 mt-4">
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  setMembers([]);
                  setNotes([]);
                  setAvailableMembers(predefinedMembers);
                }}
                className={clsx(
                  "px-6 py-2 font-medium rounded-md border border-gray-300",
                  "text-gray-700 hover:bg-gray-100",
                )}
              >
                Annuler
              </button>
              <button
                type="submit"
                className={clsx(
                  "px-6 py-2 font-medium text-white rounded-md",
                  "bg-blue-600 hover:bg-blue-700",
                )}
              >
                Envoyer !
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TaskForm;
