import React, { useState } from "react";

import ArticleMedia from "../ArticleMedia"; // Assurez-vous d'importer ArticleMedia

const ImageGalleryBlock = ({ content, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const addImage = () => onChange([...content, ""]);

  const updateImage = (index, value) => {
    const newImages = [...content];
    newImages[index] = value;
    onChange(newImages);
  };

  const removeImage = (index) => {
    const newImages = content.filter((_, i) => i !== index);
    onChange(newImages);
  };

  const handleImageSelect = (imageUrl) => {
    if (selectedIndex !== null) {
      updateImage(selectedIndex, imageUrl);
      setShowModal(false); // Ferme la modal après sélection
    }
  };

  const openModal = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-2 mb-4">
        {content.map((image, index) => (
          <div key={index} className="relative">
            <button
              onClick={() => openModal(index)}
              className="w-full p-2 mb-2 border rounded focus:border-blue-500 outline-none"
            >
              {image ? (
                <img
                  src={image}
                  alt={`Aperçu ${index + 1}`}
                  className="max-w-full h-auto rounded shadow mb-2"
                />
              ) : (
                "Choisir une image"
              )}
            </button>
            {image && (
              <button
                onClick={() => removeImage(index)}
                className="absolute top-2 right-2 text-red-500 hover:text-red-600 transition"
              >
                &times;
              </button>
            )}
          </div>
        ))}
      </div>

      <button
        onClick={addImage}
        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition mt-4"
      >
        Ajouter une image
      </button>

      {/* Modal pour sélectionner l'image */}
      {showModal && <ArticleMedia onSelect={handleImageSelect} />}
    </div>
  );
};

export default ImageGalleryBlock;
