export default [
  {
    title: "thomas",
    date: "1724928960",
    author: "thomas ",
    description:
      "n asperiores laborum ut maiores ullam ut minima consequatur. 33 amet fuga eos dolorem quae ",
    banner:
      "https://cdn.discordapp.com/attachments/1112365380602691616/1124344947273904238/2023-06-30_16.25.18.png?ex=66cfdc14&is=66ce8a94&hm=0576a3e2513b8b548d1c79c5ec0d1a2fcfe8e836384f856fb0dc538a6abe48bb&",
    data: [
      {
        id: "block-1724844492064",
        type: "TITLE",
        content: "on recrute",
        settings: {
          fontSize: "4",
          style: ["italic", "bold"],
          position: "center",
          textCase: "uppercase",
        },
      },
      {
        id: "block-1724838493546",
        type: "IMAGE",
        content:
          "https://cdn.discordapp.com/attachments/1112365380602691616/1261280298402975797/IMG_0917.png?ex=66cf0750&is=66cdb5d0&hm=5d161cc79e4dd63c2d4bbb50d8f45fa3eceb7bedd78cfea254344caa03f7eeb1&",
      },
      {
        id: "block-1724844492044",
        type: "TITLE",
        content: "nos realisation",
        settings: {
          fontSize: "1",
          style: ["italic", "bold"],
          position: "left",
          textCase: "uppercase",
        },
      },
      {
        id: "block-1724838515327",
        type: "PARAGRAPH",
        content:
          "<p><strong>Lorem ipsum dolor sit amet.</strong> In asperiores laborum ut maiores ullam ut minima consequatur. 33 amet fuga eos dolorem quae et nihil dolorum non incidunt delectus? Quo laudantium quae ut voluptatem eaque et explicabo modi. Eum reprehenderit internos eum officia ullam et voluptatem commodi ut suscipit unde ex tempora sunt ut aliquam placeat!</p><p><br></p><p><br></p><p>Lorem ipsum dolor sit amet. In asperiores laborum ut maiores ullam ut minima consequatur. 33 amet fuga eos dolorem quae et nihil dolorum non incidunt delectus? Quo laudantium quae ut voluptatem eaque et explicabo modi. Eum reprehenderit internos eum officia ullam et voluptatem commodi ut suscipit unde ex tempora sunt ut aliquam placeat!</p>",
      },
      {
        id: "block-1724838551784",
        type: "IMAGE_BLOCK",
        content: [
          "https://cdn.discordapp.com/attachments/1112365380602691616/1124345757936390194/2023-06-30_16.26.23.png?ex=66cfdcd6&is=66ce8b56&hm=2e204716b0c9c642877386ffba43bae5f139285247e02642a8456b3807cb02af&",
          "https://cdn.discordapp.com/attachments/1112365380602691616/1124345758586523869/2023-06-30_16.26.43.png?ex=66cfdcd6&is=66ce8b56&hm=ab0b31e0f73be6abb3f2d7d4511d2290cbe0a862e92c66074876930663a8f4ab&",
          "https://cdn.discordapp.com/attachments/1112365380602691616/1124345757336608768/2023-06-30_16.28.24.png?ex=66cfdcd5&is=66ce8b55&hm=3ec8b61a0dd5353d63156eb3aec326f9a34baf5704bede7f1106a5bcae0c8e66&",
          "https://cdn.discordapp.com/attachments/1112365380602691616/1124346951073284106/2023-06-30_16.32.10.png?ex=66cfddf2&is=66ce8c72&hm=9b274ca78c2f141956e252c4b3e996cff45c8899109501c3bf3b443bc0271e0c&",
          "https://cdn.discordapp.com/attachments/1112365380602691616/1124346951073284106/2023-06-30_16.32.10.png?ex=66cfddf2&is=66ce8c72&hm=9b274ca78c2f141956e252c4b3e996cff45c8899109501c3bf3b443bc0271e0c&",
        ],
      },
      {
        id: "block-1724838612287",
        type: "PARAGRAPH",
        content:
          "<p><strong>Lorem ipsum dolor sit amet.</strong> In asperiores laborum ut maiores ullam ut minima consequatur. 33 amet fuga eos dolorem quae et nihil dolorum non incidunt delectus? Quo laudantium quae ut voluptatem eaque et explicabo modi. Eum reprehenderit internos eum officia ullam et voluptatem commodi ut suscipit unde ex tempora sunt ut aliquam placeat!</p><p><br></p>",
      },
      {
        id: "block-1724850032042",
        type: "ACTION_BUTTON",
        content: "Nous Rejoindre !",
        settings: {
          style: "exampleStyle",
          redirectUrl: "/",
        },
      },
    ],
  },
];
