import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload, FaDownload, FaTrashAlt } from "react-icons/fa";

const ImageUploader = () => {
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 1024,
    height: 600,
  });
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);

  const onDropImage = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDropLogo = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setLogo(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } =
    useDropzone({
      onDrop: onDropImage,
      accept: "image/*",
    });

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } =
    useDropzone({
      onDrop: onDropLogo,
      accept: "image/*",
    });

  useEffect(() => {
    if (image) {
      const canvas = new fabric.Canvas(canvasRef.current, {
        width: canvasDimensions.width,
        height: canvasDimensions.height,
      });
      fabricCanvasRef.current = canvas;

      fabric.Image.fromURL(image, (img) => {
        const aspectRatio = img.width / img.height;
        const newWidth = canvas.getWidth();
        const newHeight = newWidth / aspectRatio;

        img.set({
          left: 0,
          top: 0,
          selectable: false,
          hasBorders: false,
          hasControls: false,
        });

        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        setCanvasDimensions({ width: newWidth, height: newHeight });

        img.scaleToWidth(newWidth);
        canvas.add(img);
        canvas.renderAll();
      });
    }
  }, [image]);

  useEffect(() => {
    if (logo) {
      const canvas = fabricCanvasRef.current;
      if (canvas) {
        fabric.Image.fromURL(logo, (logoImg) => {
          logoImg.set({
            left: 100,
            top: 100,
            selectable: true,
            hasBorders: true,
            hasControls: true,
          });
          canvas.add(logoImg);
          canvas.setActiveObject(logoImg);
          canvas.renderAll();
        });
      }
    }
  }, [logo]);

  const downloadImage = () => {
    const canvas = fabricCanvasRef.current;
    const dataURL = canvas.toDataURL("image/jpeg", 0.9);
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "image-with-logo.jpg";
    link.click();
  };

  const resetCanvas = () => {
    const canvas = fabricCanvasRef.current;
    if (canvas) {
      canvas.clear();
      setImage(null);
      setLogo(null);
      setCanvasDimensions({ width: 1024, height: 600 });
    }
  };

  return (
    <div className="flex flex-col items-center p-6 mx-auto bg-gray-100 min-h-screen w-full">
      <h2 className="text-4xl font-extrabold mb-6 text-gray-800 flex items-center">
        instragram logo maker
      </h2>

      <div className="flex gap-6 mb-6">
        {/* Dropzone for background image */}
        <div
          {...getImageRootProps()}
          className="flex flex-col items-center border-2 border-dashed border-gray-300 p-6 cursor-pointer text-center bg-white rounded-lg shadow-lg hover:border-gray-500 transition-all ease-in-out duration-300"
        >
          <input {...getImageInputProps()} />
          <FaUpload className="text-4xl mb-2 text-blue-500" />
          <p className="text-gray-700 text-lg">
            Glissez-déposez une image de fond ici, ou cliquez pour en
            sélectionner une
          </p>
        </div>

        {/* Dropzone for logo */}
        <div
          {...getLogoRootProps()}
          className="flex flex-col items-center border-2 border-dashed border-gray-300 p-6 cursor-pointer text-center bg-white rounded-lg shadow-lg hover:border-gray-500 transition-all ease-in-out duration-300"
        >
          <input {...getLogoInputProps()} />
          <FaUpload className="text-4xl mb-2 text-green-500" />
          <p className="text-gray-700 text-lg">
            Glissez-déposez un logo ici, ou cliquez pour en sélectionner un
          </p>
        </div>
      </div>

      {image && (
        <div className="w-full max-w-5xl mb-6">
          <canvas
            ref={canvasRef}
            className="border border-gray-700 w-full h-auto"
          />
        </div>
      )}

      {(image || logo) && (
        <div className="flex space-x-4">
          <button
            onClick={downloadImage}
            className="p-2 flex gap-2 items-center duration-500 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-blue-700 transition-bg"
          >
            <FaDownload className="mr-2" />
            Télécharger l'image
          </button>
          <button
            onClick={resetCanvas}
            className="p-2 flex gap-2 items-center duration-500 p bg-red-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-red-700 transition-bg"
          >
            <FaTrashAlt className="mr-2" />
            Réinitialiser
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
