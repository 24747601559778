import React, { useEffect, useState } from "react";
import {
  getArticles,
  publishArticle,
  setStatusImage,
} from "../../../../services/services";
import { useFilter } from "react-aria";
import { Button, Input, Label, SearchField } from "react-aria-components";
import ArticleTable from "./ArticleTable";

const ArticleManagement = () => {
  const [articleData, setArticleData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeOptions = [25, 50, 75];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { response, data } = await getArticles();
        console.log(response, data);
        if (response.status === 200) {
          setArticleData(data.data);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  let { contains } = useFilter({
    sensitivity: "base",
  });

  let matchedComposers = articleData.filter((composer) => {
    return value.length > 3
      ? contains(composer.TITLE.toLowerCase().trim(), value.toLowerCase())
      : composer;
  });

  const totalItems = matchedComposers.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    if (page === 0) return setCurrentPage(1);
    if (page > totalPages) return setCurrentPage(totalPages);
    setCurrentPage(page);
  };

  return (
    <section>
      <section className="bg-gray-100  w-full  p-2  overflow-hidden">
        <div className="flex gap-3 items-center justify-between w-full">
          <SearchField className="flex items-center  p-2  ">
            <Label className="mr-2 text-black font-semibold">
              Rechercher un article
            </Label>
            <Input
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setCurrentPage(1);
              }}
              className=" p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Entrez le nom de l'article..."
            />
            <Button
              onPress={() => setValue("")}
              className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
              aria-label="Clear search"
            >
              ✕
            </Button>
          </SearchField>

          <div className="flex items-center gap-3 text-white">
            <Label className="font-semibold text-black text-right pl-2">
              Résultat:{" "}
            </Label>
            <p className=" center text-black  px-2 rounded-md border-[#8f8f8f] border p-2">
              {matchedComposers.length} / {articleData.length} Article
            </p>
          </div>
        </div>

        <section className=" w-full h-[75vh] mt-2 rounded max-h-[73vh] !overflow-scroll">
          <ArticleTable
            data={matchedComposers}
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
          />
        </section>
        <div className="flex justify-between mt-2">
          <div>
            <Label className="text-black">Éléments par page: </Label>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className=" text-black px-2 rounded-md border-[#8f8f8f] border"
            >
              {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 text-back">
              <p className="">{`Page `}</p>
              <Input
                className="bg-transparent w-8 text-center border border-black rounded outline-none"
                value={currentPage}
                onChange={(e) => {
                  handlePageChange(Number(e.target.value));
                }}
              />
              <p className="">{` / ${totalPages}`}</p>
            </div>

            <Button
              onPress={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className=" text-black px-2 rounded-md border-[#8f8f8f] border"
            >
              Précédent
            </Button>
            <Button
              onPress={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className=" text-black px-2 rounded-md border-[#8f8f8f] border"
            >
              Suivant
            </Button>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ArticleManagement;
